import React, { useState, useEffect, useCallback, useContext } from "react";
import { CContainer, CCol, CRow, CSelect, CImg, CSpinner } from "@coreui/react";
import { useHistory } from "react-router";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import "../styles/style.css";
import "../styles/react-confirm-alert.css";
import { NavigationContext, TopicCatTreeContext } from "./Wrapper";
import xlsxParser from "xlsx-parse-json";
import { dataSetActions, sidebarActions } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { FaPlus, FaRegChartBar } from "react-icons/fa";
import { RiCloseLine } from "react-icons/ri";
import LockIcon from "../assets/icons/lock.svg";
import GalleryIcon from "../assets/icons/gallery.svg";
import RightArrow from "../assets/icons/right-arrow.svg";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const maxRowsAllowed = 200;

const Dashboard = () => {
  const history = useHistory();

  const { dispatchNav } = useContext(NavigationContext);
  const { datasetLoader } = useSelector((state) => state.datasets);
  
  useEffect(() => {
    if (datasetLoader && typeof datasetLoader == 'undefined') {
      setCheckLoader(true)
    } else if (!datasetLoader) {
      setCheckLoader(false)
    }
  }, [datasetLoader])

  const [checkLoader, setCheckLoader] = useState(false);

  const dispatch = useDispatch();

  const [collapse, setCollapse] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [topicError, setTopicError] = useState(null);
  const [categories, setCategories] = useState({
    isLoading: false,
    options: [],
    value: "",
  });
  const [description, setDescription] = useState("");
  const [topic, setTopic] = useState("");
  const [excelTableData, setExcelTableData] = useState([]);
  const [excelTableFields, setExcelTableFields] = useState([]);
  const [isPublic, setIsPublic] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [OpenType, setOpenType] = useState(false);
  const [FileData, setFileData] = useState();
  const user = JSON.parse(localStorage.getItem("user"));
  const FileTags = useSelector((state) => state.datasets.tags);
  const [filesList, setFilesList] = useState([]);
  const [filesListserch, setFilesListserch] = useState([]);
  const [fileTypeName, setFileTypeName] = useState(false);
  const { topicCatTree } = useContext(TopicCatTreeContext);
  const uploadedFiles = useSelector((state) => state.datasets.datasets);

  useEffect(() => {
    dispatch(dataSetActions.getAllDataSets());
    dispatch(dataSetActions.getAllFileTags());

    // dispatch(sidebarActions.setSidebar(true));

    if (window.innerWidth < 992) {
      dispatch(sidebarActions.setSidebar(false));
    } else {
      dispatch(sidebarActions.setSidebar(true));
    }
  }, []);

  useEffect(() => {
    let filenames = [];
    let newOptions = [];

    topicCatTree.forEach((item) => {
      newOptions.push(createOption(item.category));
      item.topics.forEach((topic) => {
        filenames.push({ file: topic.fileName, color: topic.color });
      });
    });

    if (topicCatTree.length > 0) {
      setCategories({
        isLoading: false,
        options: newOptions,
        value: newOptions[0],
      });
    }
  }, [topicCatTree]);

  useEffect(() => {
    setFilesList(uploadedFiles);
    setFilesListserch(uploadedFiles);
  }, [uploadedFiles]);

  const StyledDropzone = () => {

    const onDrop = useCallback((acceptedFiles) => {
      setCheckLoader(false)
      setFileLoading(true);
      for (let i = 0; i < acceptedFiles.length; i++) {
        xlsxParser
          .onFileSelection(acceptedFiles[i])
          .then(async (data) => {
            const userData = Object.values(data)[0];
            const rows = userData.slice(0, maxRowsAllowed);
            let rowData = [];
            let photo = [];
            for (let i = 0; i < rows.length; i++) {
              const row = rows[i];

              if (Object.keys(row).some((x) => x.toLowerCase().includes("_photo".toLowerCase()))) {
                const arr = Object.keys(row).filter((item) => item.match("_photo"))[0];
                const matchedArr = arr.split("_")[0];
                photo = row;
                const str = {
                  name: row[matchedArr],
                  photoUrl: row[arr],
                };
                photo[matchedArr] = JSON.stringify(str);
              }

              const values = Object.keys(row).map((key) => {
                return row[key];
              });
              rowData.push(values);
            }

            let new_header = [];
            Object.keys(userData[0]).filter((item) => {
              if (!item.includes("_photo")) {
                new_header.push(item);
              }
            });
            const headers = new_header;

            const fileName = acceptedFiles[i].name;
            if (headers && fileName && rowData) {
              setFileData({
                headers: headers,
                fileName: fileName,
                rowData: rowData,
              });
            }
            if (user.role === "admin") {
              setOpenType(true);
              setIsUser(false)
            } else {
              setIsUser(true)
              setOpenType(true);
            }

          })
          .catch((err) => { });
      }
      setCollapse(false);
      setFileLoading(false);
      setExcelTableData([]);
      setExcelTableFields([]);
    }, []);

    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
    } = useDropzone({ accept: ".csv,.xlsx,.xls", onDrop });
    return (
      <div className="container dragImg">
        <Container
          {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
        >
          <input {...getInputProps()} />
          <p>Upload new data set</p>
          <FaPlus className="addFile" />
          <em className="blue-color">.csv, .xlsx and .xls</em>
        </Container>
      </div>
    );
  };

  const userTypeUploadData = () => {
    const type = "private";
    const d = new Date();
    const createdAt = d.getTime();
    const updatedAt = d.getTime();
    dispatch(dataSetActions.createDataSet( FileData.rowData,FileData.headers, FileData.fileName, type, description,new Date(),new Date()));
    handleCatCreate(FileData.fileName, type);
    setOpenType(false);
  };

  const uploadPrivateFileType = () => {
    const type = "private";
    const d = new Date();
    const createdAt = d.getTime();
    const updatedAt = d.getTime();
    dispatch(
      dataSetActions.createDataSet(
        FileData.rowData,
        FileData.headers,
        FileData.fileName,
        type,
        description,
        new Date(),
        new Date()
      )
    );
    handleCatCreate(FileData.fileName, type);
    setOpenType(false);
  };

  const uploadPublicFileType = () => {
    const type = "public";
    const d = new Date();
    const createdAt = d.getTime();
    const updatedAt = d.getTime();
    dispatch(
      dataSetActions.createDataSet(
        FileData.rowData,
        FileData.headers,
        FileData.fileName,
        type,
        description,
        new Date(),new Date()
      )
    );
    handleCatCreate(FileData.fileName, type);
    setOpenType(false);
  };

  const handleActiveItem = (file) => {
    dispatch(dataSetActions.setFileList(file));
    history.push(`/${file.fileType}/${file?.id}`);
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleCatCreate = async (inputValue, type) => {
    setCategories({ isLoading: true });

    setTimeout(() => {
      const { options } = categories;
      const newOption = createOption(inputValue);
      setCategories({
        isLoading: false,
        options: [...options, newOption],
        value: newOption,
      });
      handleSubmit([...options, newOption], newOption, inputValue, type);
    }, 1000);
  };

  // const handleSetPrivate = () => {
  //   let type = "";
  //   if (isPublic) {
  //     type = "public";
  //   } else {
  //     type = "private";
  //   }
  //   setIsPublic(!isPublic);
  //   setFileTypeName(true);
  //   if (uploadedFiles && uploadedFiles.length) {
  //     const data = uploadedFiles.filter((item) => item.fileType == type);
  //     setFilesList(data);
  //   }
  // };

  const handleChange = (e) => {
    let type = e.target.value;
    if (type === "private") {
      setIsPublic(false);
      setList(type);
    } else if (type === "public") {
      setIsPublic(true);
      setList(type);
    } else {
      setFilesList(uploadedFiles);
    }
  };

  const setList = (type) => {
    setFileTypeName(true);
    if (uploadedFiles && uploadedFiles.length) {
      const data = uploadedFiles.filter((item) => item.fileType === type);
      setFilesList(data);
    }
  };

  const handleSubmit = async (options, newOption, topic, type) => {
    setCheckLoader(false)
    const optionsData = {
      isLoading: false,
      options: options,
      value: newOption,
    };
    let DBdata = [];
    let DBtopics = [];

    // let DBdataPublic = [];
    // let DBtopicsPublic = [];
    if (FileTags.length) {
      //   FileTags.map(item => {
      //     if(item.fileType === "private"){
      //       DBdata.push(item.data.data);
      //       DBtopics.push(item.topics.data)
      //     }else{
      //       DBdataPublic.push(item.data.data);
      //       DBtopicsPublic.push(item.topics.data)
      //     }
      //   })
      DBdata = JSON.parse(FileTags[0].data.data);
      DBtopics = JSON.parse(FileTags[0].topics.data);
    }
    const category = optionsData["value"]["label"];
    if (category === undefined) {
      setTopicError("Category is empty");
      return;
    }
    if (topic.length === 0) {
      setTopicError("Topic cannot be empty");
      return;
    }
    setTopic("");

    //create/update the topicCatTree array
    const index = topicCatTree.findIndex((item) => item.category === category);
    // const upIndex = uploadedFiles.findIndex((f) => f.fileName === activeFile);
    // const data = uploadedFiles[upIndex].data;
    let dataPayload = {};

    if (index === -1) {
      // new category, so create new one
      const newCategory = {
        category: category,
        topics: [
          {
            topic: topic,
            // data: data,
            color: "text-success",
            fileName: FileData.fileName,
          },
        ],
      };

      topicCatTree.push(newCategory);
      setTopicError(null);
      dispatchNav({
        type: "UPDATE",
        payload: { category: category, topic: topic, file: FileData.fileName },
      });
      let payload = {
        category: category,
        topic: topic,
        file: FileData.fileName,
      };
      AddData(payload, DBdata, [newCategory, ...DBtopics], type);

      dataPayload = {
        _tag: "CSidebarNavDropdown",
        name: `${category}`,
        route: "",
        show: true,
        type,
        _children: [
          {
            _tag: "CSidebarNavItem",
            name: `${topic}`,
            to: `/topic/${category}/${topic}/${FileData.fileName}`,
          },
        ],
      };
    } else {
      // add topic to this category, but only if this topic does not already exist
      let currentTopics = topicCatTree[index];
      if (
        currentTopics.topics.find((item) => item.topic === topic) !== undefined
      ) {
        setTopicError("Topic already exists");
      } else {
        currentTopics.topics.push({
          topic: topic,
          // file: data,
          color: "text-success",
          fileName: FileData.fileName,
        });

        const child = {
          _tag: "CSidebarNavItem",
          name: `${topic}`,
          to: `/topic/${category}/${topic}/${FileData.fileName}`,
        };
        // state[index]._children.push(child);
        let payload = {
          category: category,
          topic: topic,
          file: FileData.fileName,
        };
        setTopicError(null);
        AddData(payload, DBdata, [currentTopics, ...DBtopics]);
        dispatchNav({
          type: "UPDATE",
          payload: {
            category: category,
            topic: topic,
            file: FileData.fileName,
          },
        });
      }
    }
  };

  const AddData = (data, DBdata, topics, type) => {
    const { category, topic, file } = data;
    // alert('category '+category+' topic '+topic+' file '+file)
    let FileData = [...DBdata];
    const index = FileData.findIndex((item) => item.name === category);
    let parent = {};
    if (index === -1) {
      parent = {
        _tag: "CSidebarNavDropdown",
        name: `${category}`,
        route: "",
        show: true,
        type,
        _children: [
          {
            _tag: "CSidebarNavItem",
            name: `${topic}`,
            to: `/topic/${category}/${topic}/${file}`,
          },
        ],
      };
      let newData = [];
      if (FileTags.length) {
        newData = [...FileData, parent];
        dispatch(
          dataSetActions.updateDataSetTags(newData, FileTags[0].id, topics)
        );
        //dispatch(
        //  dataSetActions.updateDataSetTags(
        //   { ...FileData, parent },
        //    FileTags[0].id
        // )
        //);
      } else {
        newData = [...FileData, parent];

        dispatch(dataSetActions.setDataSetTags(newData, topics));
      }
      // return [...state, parent];
    } else {
      const child = {
        _tag: "CSidebarNavItem",
        name: `${topic}`,
        to: `/topic/${category}/${topic}/${file}`,
      };
      FileData[index]._children.push(child);
      dispatch(
        dataSetActions.updateDataSetTags([...FileData], FileTags[0].id, topics)
      );
      // return [...state];
    }
  };

  const handleInputChange = (e) => {
    const query = e.target.value;
    const filteredData = filesListserch.filter((element) => {
      return element.fileName && element.fileName.toLowerCase().includes(query.toLowerCase());
    }); 
    setFilesList(filteredData);
  };

  const imageCheckData = (data) => {
    let checkImage = false;
    if (data != null && data.length > 0) {
      data.map((item) => {
        if (JSON.stringify(item).toLowerCase().includes("photourl")) {
          checkImage = true;
          return false;
        }
      });
    }
    if (checkImage) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {uploadedFiles && uploadedFiles.length && !datasetLoader && checkLoader ? <div class="loader-wrapper">
        <div class="loader"></div>
      </div> : ' '}
      <div
        className="FileTypeModal"
        style={{ display: OpenType ? "block" : "none" }}
      >
        
        <div
          className="containers"
          style={{ display: OpenType ? "block" : "none" }}
        >
          <div>
          <RiCloseLine
            className="closeRight"
            onClick={() => setOpenType(false)}
          />
        </div>
          <div>
            <div className="description">
              <label>File Description</label>
              <textarea value={description} className="description-text" onChange={(e) => setDescription(e.target.value)} ></textarea>
            </div>
            {isUser == false && (<div className="confirmation-text">Please select file type</div>)}
            <div className="button-container">
              {isUser ?
                <button
                  className="submit-button"
                  onClick={() => userTypeUploadData()}
                >
                  submit
                </button>
                :
                <><button
                  className="cancel-button"
                  onClick={() => uploadPrivateFileType()}
                >
                  Private
                </button><button
                  className="confirmation-button"
                  onClick={() => uploadPublicFileType()}
                >
                    Public
                  </button></>
              }

            </div>
          </div>
        </div>
      </div>

      {/* <CRow>
                <CContainer fluid>
                    <CCard>
                        <CCardHeader>
                            <CButton color="primary" onClick={toggleCollapse} className={'mb-1'}>Upload</CButton>
                            <span className="m-4">Click to import your files</span>
                        </CCardHeader>
                        <CCollapse show={collapse}>
                            <CCardBody>
                                <StyledDropzone />
                            </CCardBody>
                        </CCollapse>
                    </CCard>
                </CContainer>
            </CRow>   */}
      {/* 
        <CRow key={i} className="no-gutters m-1">
            <CCol sm="10">
                <CListGroupItem key={i} style={{ cursor: 'pointer' }} className="excel-list-group" onClick={() => handleActiveItem(file)}>
                    <p>{file.fileName}</p>
                </CListGroupItem>
            </CCol>
            <CCol sm="2" className="p-1 excel-list-group-btn my-auto pull-right">
                <CButton onClick={() => handleDelete(file)} className="ml-2 bg-danger text-white"><CIcon name="cil-trash" /></CButton>
            </CCol>
        </CRow> */}
      {/* <div className="switch-card">
        <div>Private</div>
        <div className="switchCustom">
          <CSwitch
            checked={isPublic}
            onChange={() => handleSetPrivate()}
            shape={"pill"}
            color={"primary"}
            size={"lg"}
          />
        </div>
        <div>Public</div>
      </div> */}
      {/* <div className="radio-container">
        <div className="radio-group">
          <label htmlFor="All">All</label>
          <input
            type="radio"
            name="radio"
            value="all"
            onChange={(e) => handleChange(e)}
            defaultChecked
          />
        </div>
        <div className="radio-group">
          <label htmlFor="private">Private</label>
          <input
            type="radio"
            name="radio"
            onChange={(e) => handleChange(e)}
            value="private"
          />
        </div>
        <div className="radio-group">
          <label htmlFor="public">Public</label>
          <input
            type="radio"
            name="radio"
            onChange={(e) => handleChange(e)}
            value="public"
          />
        </div>
      </div> */}
      <CContainer fluid className="p-0 bg-gray text-black">
        <CContainer>
          <div className="manageSide">
            <div className="catlog_header">
              <div>
                <h2 className="cat_header">Catalog</h2>
              </div>
              <div className="managePosition">
                <input
                  type="search"
                  className="p-2 searchInput"
                  placeholder="Search"
                  onChange={(e) => handleInputChange(e)}
                />
                <button
                  type="submit"
                  color="success"
                  variant="outline"
                  className="searchButton"
                >
                  Search
                  <CImg src={RightArrow} style={{ marginLeft: "12px" }} />
                </button>
              </div>
            </div>
          </div>
          <CSelect
            custom
            name="filterSelect"
            className="manageSide"
            style={{ width: "188px" }}
            onChange={(e) => {
              handleActiveItem(
                filesList &&
                filesList.length &&
                filesList.filter((item) => item.id === e.target.value)[0]
              );
            }}
          >
            <option>all data sets</option>

            {filesList &&
              filesList.length &&
              filesList.map((dataitem, dataindex) => {

                if (dataitem.fileName !== null) {
                  return (<option
                    key={dataindex}
                    value={dataitem.id}
                    atr-data={dataitem.length}
                  >
                    {dataitem.fileName.split(".xlsx")[0]}
                  </option>)
                }

              })}
          </CSelect>
          <CRow className='allDataRow'>
            <CCol lg="3" className={`dragFilesCol ${user?'':'disabled'}`}>
              {/* <CCardHeader>
                        <CButton color="primary" onClick={toggleCollapse} className={'mb-1'}>Upload</CButton>
                        <span className="m-4">Click to import your files</span>
                    </CCardHeader> */}
              <StyledDropzone />
            </CCol>
            {filesList &&
              filesList.length &&
              filesList.map((file, i) => {
                if (file.fileName != null) {
                  return (
                    <CCol
                      lg="3"
                      md="5"
                      sm="1"
                      key={i}
                      className="dashboard all_details"
                    >
                      <div className="classOne">
                        <div>
                          <span
                            className={
                              file.fileType === "public" ? "colorRed" : "colorGreen"
                            }
                          >
                            {file.fileType === "public" ? (
                              ""
                            ) : (
                              <CImg src={LockIcon} />
                            )}
                          </span>
                        </div>
                        <p
                          className="fileNameList"
                          onClick={() => handleActiveItem(file)}
                        >
                          {file.fileName && (file.fileName.split(".xlsx")[0])}
                        </p>
                        <p className="this_font">{file.description}</p>
                      </div>
                      <div className="lock_icons classTwo">
                        {/* <CImg src={LockIcon} /> */}
                        {imageCheckData(file.data.rows) ? (
                          <CImg src={GalleryIcon} className="gallary_icon " />
                        ) : (
                          ""
                        )}
                        {/* <CImg src={GalleryIcon} className={"gallary_icon "+imageCheckData(file.data.rows)} /> */}
                        {/* <CImg src={FaChartBar} /> */}
                        <FaRegChartBar className="barChart" />
                      </div>
                    </CCol>
                  )
                }
              })}
          </CRow>
        </CContainer>
      </CContainer>
    </>
  );
};

export { Dashboard };
