import React, { useState, useContext, useEffect } from "react";
import {
  CCard,
  CCardBody,
  CContainer,
  CCardHeader,
  CCol,
  CRow,
  CSpinner
} from "@coreui/react";
import { Cards, FileDetails } from ".";
import "../styles/style.css";
import { useDispatch, useSelector } from "react-redux";
import { dataSetActions } from "../actions";
import { useParams } from "react-router-dom";
import {FaArrowLeft,FaInfo} from "react-icons/fa";


const maxRowsAllowed = 200;

const Topic = (props) => {
  const dispatch = useDispatch();
  const { category, topic, fileName, cardColors ,IsPicture, filteredData, shuffledClick, setShuffledClick, IsCard} = props;

  const [cardData, setCardData] = useState({
    cardHeader: [],
    cardRows: [],
    filterFields: {}, // Set of filter values
  });

  const [isLoading, setisLoading] = useState(false)
  const [reload, setReload] = useState("");   // for update component

  // useEffect(() => {
  //   dispatch(dataSetActions.getAllFileTags());
  //   dispatch(dataSetActions.getAllDataSets());
  // }, []);

  const FileTags = useSelector((state) => state.datasets.tags);
  // const datasets = useSelector((state) => state.datasets.datasets);
  const setFiles = useSelector((state) => state.datasets.file);

  useEffect(()=>{
    if (fileName !== "" && reload !== fileName) {
      setisLoading(true);
      setReload(fileName);
    }
  },[])


  useEffect(() => {
    let topicCatTree = [];
    // if (FileTags && datasets && FileTags.length && datasets.length) {
    //   console.log(FileTags);
    //   topicCatTree = JSON.parse(FileTags[0].topics.data).map((item) => {
    //     const { topics } = item;
    //     const withDataTopics = Array.isArray(topics)
    //       ? topics.map((topic) => {
    //           return {
    //             ...topic,
    //             data: datasets.find(
    //               (innerItem) => topic.fileName === innerItem.fileName
    //             ).data,
    //           };
    //         })
    //       : [];
    //     return {
    //       ...item,
    //       topics: withDataTopics,
    //     };
    //   });    
    // }

    // get the fileMeta from topicCatTree for this file
    // const index = topicCatTree.findIndex((item) => item.category === category);
    // if this category is not found, do nothing, return
    // if (index === -1) return;
    // console.log("topicCatTree", topicCatTree);
  
    // // if this category found, now look for the topic in the "topics" array
    // const fileMeta = topicCatTree[index]["topics"].find(
    //   (f) => f.fileName === fileName
    // );
    const data = setFiles.data;
    let userData = [];
    // // create the filter fields as a set ie. unique items
    let fields = {};
    if (Object.keys(data).length > 0) {
      for (let key of data.headers) {
        fields[key] = [];
      }
      data.rows.forEach((row) => {
        let userDataRow = {};
        for (let index = 0; index < row.length; index++) {
          let key = data.headers[index];
          if(key){
            if (!fields[key].includes(row[index])) {
              fields[key].push(row[index]);
            }
            userDataRow[key] = row[index];
          }
        }
        userData.push(userDataRow);
      });
      setCardData({
        cardHeader: data.headers,
        cardRows: userData,
        filterFields: fields,
      });
      setReload(fileName);
      dispatch(dataSetActions.setActiveCardFile({
        cardHeader: data.headers,
        cardRows: userData,
        filterFields: fields,
      }))
      setisLoading(false)
    }

    // console.log({
    //   cardHeader: data.headers,
    //   cardRows: userData,
    //   filterFields: fields,
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload,setFiles]);

  const setFileName = (fileName) => {
    let files = fileName.split(".")
    return files[0]
  }

  return (
    <CContainer>
      {isLoading &&
        <CSpinner component="span" size="sm" aria-hidden="true" />}
        <CCard className="topic">
          {/* <CCardHeader className=" border-bottom p-3 mb-0 text-black h5 topic_header">
            <CRow>
              <div className="aerrow_left">
                <FaArrowLeft />
              </div>
              <CCol sm="6" className="custom-card-header ">
                {setFileName(fileName)} <FaInfo className="i_ball"/>
              </CCol>
              <div className="i_ball">
                 <FaInfo />
              </div>
            </CRow>
          </CCardHeader> */}
          <CCardBody><Cards cardData={cardData} fileName={reload}  cardColors={cardColors} showPicture={IsPicture} IsCard={IsCard} resetFilteredData={filteredData} setShuffledClick={setShuffledClick} shuffledClick={shuffledClick}/></CCardBody>
        </CCard>
     {/* }  */}
    </CContainer>
  );
};

export { Topic };
