import config from '../config';
import { authHeader } from '../helpers';

const getAllFileTags = async () => {
    const user = await JSON.parse(localStorage.getItem('user'));
    if(user){
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };
        return fetch(`${config.apiUrl}/filetag/user/${user.id}`, requestOptions).then(handleResponse);
    }
}

const createFileTags = async(data,topics,type) => {
    const user = await JSON.parse(localStorage.getItem('user'));
        if(topics !== undefined ){
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken},
                body: JSON.stringify({
                    fileType: type,
                    userId: user.id,
                    data: {"data" : JSON.stringify(data)},
                    topics: {"data" : JSON.stringify(topics)}
                })
            };
            return fetch(`${config.apiUrl}/filetag`, requestOptions).then(handleResponse);
        }
}

const updateFileTags = async (data,id,topics,type) => {
    const user = await JSON.parse(localStorage.getItem('user'));
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken},
            body: JSON.stringify({
                fileType: type,
                id: id,
                userId: user.id,
                data: {"data" : JSON.stringify(data)},
                topics: {"data" : JSON.stringify(topics)}

            })
        };
    
        return fetch(`${config.apiUrl}/filetag`, requestOptions).then(handleResponse);
}

const handleResponse = (response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}


export const fileTagsServices = {
   getAllFileTags,
   createFileTags,
   updateFileTags
};