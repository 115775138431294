import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CDataTable,
  CSpinner,
  CSwitch,
  CButton,
  CCardHeader,
  CDropdown,
  CDropdownMenu,
  CDropdownItem,
  CDropdownToggle,
  CImg
} from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import { NavigationContext, TopicCatTreeContext } from "./Wrapper";
import { Cards, SaveMode } from ".";
import sizeof from "object-sizeof";
import Scrollbar from "react-scrollbars-custom";
import CIcon from "@coreui/icons-react";
import { useHistory } from "react-router";
import { dataSetActions, sidebarActions } from "../actions";
import { Topic } from "./Topic";
import { TableDetails } from './TableDetails';
import InfoIcon from '../assets/icons/info.svg'
import { FaArrowLeft, FaInfo, FaRandom, FaRedo, FaPalette } from "react-icons/fa";
import _, { shuffle } from "lodash"
import {useParams} from 'react-router-dom';

const cardColors = [
  {
    color: "bg-gradient-primary",
    text: "text-white",
  },
  {
    color: "bg-gradient-info",
    text: "text-white",
  },
  {
    color: "bg-gradient-warning",
    text: "text-primary",
  },
  {
    color: "bg-gradient-success",
    text: "text-white",
  },
  {
    color: "bg-gradient-danger",
    text: "text-white",
  },
  {
    color: "bg-gradient-dark",
    text: "text-white",
  },
  {
    color: "",
    text: "text-black",
  },
  {
    color: "bg-secondary",
    text: "text-black",
  },
];

const FileDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { dispatchNav } = useContext(NavigationContext);
  const { stateNav } = useContext(NavigationContext);
  const [excelTableData, setExcelTableData] = useState([]);
  const [excelTableFields, setExcelTableFields] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);
  const [activeFile, setActiveFile] = useState(null);
  const [File, setFile] = useState(false);
  const [topic, setTopic] = useState("");
  const [shuffledClick, setShuffledClick] = useState("");
  const [filteredData, setFilteredData] = useState(null);
  const activeCardList = useSelector((state) => state.datasets.activeCard)
  const user = JSON.parse(localStorage.getItem("user"));
  const [cardColor, setCardColor] = useState({
    text: "text-black",
  });

  const [toggle, setToggle] = useState('cards');
  const [categories, setCategories] = useState({
    isLoading: false,
    options: [],
    value: "",
  });
  const [topicError, setTopicError] = useState(null);
  const [ActiveFileType, setActiveFileType] = useState(null)
  const [IsCard, setIsCard] = useState(false)
  const [handleShowImage, sethandleShowImage] = useState(false)
  const [IsPicture, setIsPicture] = useState(true)
  const [IsPictureShow, setIsPictureShow] = useState(false)

  const { topicCatTree } = useContext(TopicCatTreeContext);
  const setFiles = useSelector((state) => state.datasets.file);

  // const setImages = useSelector((state) => state.datasets.file)
  const uploadedFiles = useSelector((state) => state.datasets.datasets);
  const FileTags = useSelector((state) => state.datasets.tags);

  useEffect(() => {
    dispatch(dataSetActions.getAllDataSets());
    dispatch(sidebarActions.setSidebar(false));
  }, [])

  const {cardId} = useParams();
  useEffect(()=>{
    if(uploadedFiles && uploadedFiles.length > 0){
      let findCard = uploadedFiles.filter((item)=>{
        if(item.id == cardId){
          return item
        }
      })
      if(findCard && findCard.length>0 && findCard[0].fileType == "public"){
        dispatch(dataSetActions.setFileList(findCard[0]));
      }else if(!user){
        history.push("/");
      }else if(user && findCard && findCard.length>0 && findCard[0].fileType == "private"){
        dispatch(dataSetActions.setFileList(findCard[0]));
      }
    }
  },[uploadedFiles])

  const activeButtonList = useSelector((state) => state.image.image)
  useEffect(() => {
    if (!setFiles) {
      // history.push("/");
    }
    setFileLoading(true)
    handleActiveItem(setFiles);
    // setIsCard(false)
    setFileLoading(false)
    const data = setFiles && setFiles.data && setFiles.data.rows.length > 0 ? setFiles.data.rows : null
      let checkImage = false;
      if (data != null && data.length > 0) {
        data.map((item) => {
          if (JSON.stringify(item).toLowerCase().includes("photourl")) {
            checkImage = true;
            setIsPictureShow(false);
          }
        });
      }
      if (checkImage) {
        setIsPictureShow(true);
      } else {
        setIsPictureShow(false);
      }
  }, [setFiles, uploadedFiles]);


  const handleTopicChange = (topic) => {
    setTopic(topic);
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  // const handleShowImageAction = () => {
  //   sethandleShowImage(true)
  // };
  const getFileToTopic = () => {
    for (let item of topicCatTree) {
      for (let i = 0; i < item.topics.length; i++) {
        if (item.topics[i].fileName === activeFile) {
          return item.topics[i].topic;
        }
      }
    }
    return "None";
  };

  const getFileToCategory = () => {
    for (let item of topicCatTree) {
      for (let i = 0; i < item.topics.length; i++) {
        if (item.topics[i].fileName === activeFile) {
          return item.category;
        }
      }
    }
    return "None";
  };

  const handleCatCreate = (inputValue) => {
    setCategories({ isLoading: true });
    setTimeout(() => {
      const { options } = categories;
      const newOption = createOption(inputValue);
      setCategories({
        isLoading: false,
        options: [...options, newOption],
        value: newOption,
      });
    }, 1000);
  };
  const handleCatChange = (newValue, actionMeta) => {
    setCategories({ ...categories, value: newValue });
  };

  const handleActiveItem = (file) => {
    if (setFiles && !File) {
      setActiveFileType(file.fileType)
      setFileLoading(true);
      setActiveFile(setFiles.fileName);
      const rows = setFiles.data.rows;
      const headers = setFiles.data.headers;
      if(user){
        !headers.includes("Action") &&
        headers.push("Action");
      }

      let rowData = [];
      if (rows != null) {
        for (let i = 0; i < rows.length; i++) {
          let row = {};
          for (let j = 0; j < headers.length; j++) 
            row[headers[j]] = rows[i][j] ? rows[i][j] : '';
          rowData.push(row);
        }
        setExcelTableData(rowData);

        setExcelTableFields(headers);
        setFileLoading(false);
        setFile(true);
      }

    } else if (file) {
      setActiveFileType(file.fileType)
      setFileLoading(true);
      setActiveFile(file.fileName);
      const rows = file.data.rows;
      const headers = file.data.headers;
      !headers.includes("Action") &&
      headers.push("Action");
      let rowData = [];
      for (let i = 0; i < rows.length; i++) {
        let row = {};

        for (let j = 0; j < headers.length; j++) 
          row[headers[j]] = rows[i][j] ? rows[i][j] : '';
        rowData.push(row);
      }

      setExcelTableData(rowData);
      setExcelTableFields(headers);
      setFileLoading(false);
    }
  };

  const handleTableField = (field) => {
    var tempIndex;
    if(field.includes("photoUrl")) {
       field.map((item, index) => {
        if(item === "photoUrl"){ 
          tempIndex = index
        }
      })
      field.splice(tempIndex, 2); 
      return field
    } else {
      return field
    }
  }

  const handleSubmit = () => {
    let DBdata = [];
    let DBtopics = [];

    if (FileTags.length) {
      DBdata = JSON.parse(FileTags[0].data.data);
      DBtopics = JSON.parse(FileTags[0].topics.data);
    }
    const category = categories["value"]["label"];
    if (category === undefined) {
      setTopicError("Category is empty");
      return;
    }
    if (topic.length === 0) {
      setTopicError("Topic cannot be empty");
      return;
    }
    setTopic("");

    //create/update the topicCatTree array
    const index = topicCatTree.findIndex((item) => item.category === category);
    const upIndex = uploadedFiles.findIndex((f) => f.fileName === activeFile);
    const data = uploadedFiles[upIndex].data;
    let dataPayload = {};

    if (index === -1) {
      // new category, so create new one
      const newCategory = {
        category: category,
        topics: [
          {
            topic: topic,
            // data: data,
            color: "text-success",
            fileName: activeFile,
          },
        ],
      };

      topicCatTree.push(newCategory);
      setTopicError(null);
      dispatchNav({
        type: "UPDATE",
        payload: { category: category, topic: topic, file: activeFile },
      });
      let payload = { category: category, topic: topic, file: activeFile };
      AddData(payload, DBdata, [newCategory, ...DBtopics]);

      dataPayload = {
        _tag: "CSidebarNavDropdown",
        name: `${category}`,
        route: "",
        show: true,
        type: ActiveFileType,
        _children: [
          {
            _tag: "CSidebarNavItem",
            name: `${topic}`,
            to: `/topic/${category}/${topic}/${activeFile}`,
          },
        ],
      };

      if (!FileTags.length) {
        // dispatch(dataSetActions.setDataSetTags(dataPayload))
      } else {
        // dispatch(dataSetActions.updateDataSetTags(dataPayload,FileTags[0].id))
      }
    } else {
      // add topic to this category, but only if this topic does not already exist
      let currentTopics = topicCatTree[index];
      if (currentTopics.topics.find((item) => item.topic === topic) !== undefined) {
        setTopicError("Topic already exists");
      } else {
        currentTopics.topics.push({
          topic: topic,
          // file: data,
          color: "text-success",
          fileName: activeFile,
        });

        const child = {
          _tag: "CSidebarNavItem",
          name: `${topic}`,
          to: `/topic/${category}/${topic}/${activeFile}`,
        };
        // state[index]._children.push(child);
        let payload = { category: category, topic: topic, file: activeFile };
        setTopicError(null);
        AddData(payload, DBdata, [currentTopics, ...DBtopics]);
        dispatchNav({
          type: "UPDATE",
          payload: { category: category, topic: topic, file: activeFile },
        });
      }
    }
  };


  const AddData = (data, DBdata, topics) => {
    const { category, topic, file } = data;
    // alert('category '+category+' topic '+topic+' file '+file)
    let FileData = [...DBdata];
    const index = FileData.findIndex((item) => item.name === category);
    let parent = {};
    if (index === -1) {
      parent = {
        _tag: "CSidebarNavDropdown",
        name: `${category}`,
        route: "",
        show: true,
        type: ActiveFileType,
        _children: [
          {
            _tag: "CSidebarNavItem",
            name: `${topic}`,
            to: `/topic/${category}/${topic}/${file}`,
          },
        ],
      };
      let newData = [];
      if (FileTags.length) {
        newData = [...FileData, parent];
        dispatch(
          dataSetActions.updateDataSetTags(newData, FileTags[0].id, topics)
        );

        //dispatch(
        //  dataSetActions.updateDataSetTags(
        //   { ...FileData, parent },
        //    FileTags[0].id
        // )
        //);
      } else {
        newData = [...FileData, parent];

        dispatch(dataSetActions.setDataSetTags(newData, topics));
      }
      // return [...state, parent];
    } else {
      const child = {
        _tag: "CSidebarNavItem",
        name: `${topic}`,
        to: `/topic/${category}/${topic}/${file}`,
      };
      FileData[index]._children.push(child);
      dispatch(
        dataSetActions.updateDataSetTags([...FileData], FileTags[0].id, topics)
      );
      // return [...state];
    }
  };
  const setFileName = (activeFile) => {
    let files = activeFile.split(".")
    return files[0]
  }

  const setCards = (type) => {
    setToggle(type)
    setIsCard(!IsCard)
  }
  const setPictures = () => {
    setIsPicture(!IsPicture)
  }
  const resetFilter = () => {
    setFilteredData({ ...filteredData, cardRows: activeCardList.cardRows })
  }

  useEffect(()=>{
  },[filteredData])

  const handleCardColor = (cardColor, textColor) => {
    setCardColor({ color: cardColor, text: textColor });
  };

  const shuffleData = () => {
    let unshuffled = activeCardList.cardRows

    let shuffled = unshuffled
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)

    setShuffledClick('click')
  }

  const BackButton ={
    width: '150px',
    height: '40px'
  }
  return (
    <div>
      {excelTableData.length ? (
        <>
          {/* <CRow className="ml-2">
            {activeFile ? (
              <p className="text-black-50">
                <em>
                  {activeFile} - Saved as Category:
                  <span className="bg-dark text-white m-1 p-1">
                    {getFileToCategory()}
                  </span>
                  , Topic:{" "}
                  <span className="bg-dark text-white m-1 p-1">
                    {getFileToTopic()}
                  </span>
                </em>
              </p>
            ) : (
              <p className="text-black-50">Select a file</p>
            )}
          </CRow> */}
          {/* {categories && (
            <SaveMode
              categories={categories}
              topic={topic}
              filename={activeFile}
              topicError={topicError}
              onCreateCategory={handleCatCreate}
              onChangeCategory={handleCatChange}
              onChangeTopic={handleTopicChange}
              onSubmit={handleSubmit}
            />
          )} */}
          <div className="aerrow_title  col-12 col-sm-10">
            <div className="aerrow_left">
              <FaArrowLeft onClick={() => history.push("/")} />
            </div>
            <CCol sm="6" className="custom-card-header ">
              {setFileName(activeFile)} <CImg
                src={InfoIcon}
              />
            </CCol>
          </div>
          <div className={IsPictureShow ? "pictureShow bottom_header col-12 col-sm-10" : "bottom_header col-12 col-sm-10"}>
            <div className="two_option">
              {!handleShowImage ? 
              <form className="table_toggle">
                <input type="radio" id="choice1" name="choice" value="creative" />
                <label for="choice1" onClick={() => setCards("table")} className={toggle === "cards" && "set-toggle-button toggle-left"}>table</label>

                <input type="radio" id="choice2" name="choice" value="productive" />
                <label for="choice2" onClick={() => setCards("cards")} className={toggle === "table" && "set-toggle-button toggle-right"}> cards</label>
              </form> :
              <CButton style={BackButton} size="sm" onClick={() => sethandleShowImage(false)} >Back to Table</CButton>
              }
              <div className={`switch_pictures pictureToggal`} style={{ display: !IsCard && IsPictureShow ? "" : "none" }}>
                <h4 className="show_text" style={{margin: 0}}>show pictures</h4>
                <CSwitch
                  checked={IsPicture}
                  data-on="ON" data-off="OFF"
                  shape={"pill"} color={'red'} 
                  className={`show_toggle ${activeButtonList ? 'showClass' : 'hideClass'}  `} 
                  size={'md'}
                  onChange={setPictures}
                />
              </div>
            </div>
            {/* {IsCard && !handleShowImage ? 
            <div>
              <CButton size="sm" style={BackButton} className="ml-2" onClick={() => handleShowImageAction() }>Go to Image action</CButton>
            </div> : ''} */}
            <div className="dropdown_tools " style={{ display: !IsCard ? "" : "none" }}>
              <CCol className="text-right main">
                <CDropdown className="dropDemo">
                  <CDropdownToggle className="text-right bg_random">
                    <FaPalette name="cil-settings" />
                  </CDropdownToggle>
                  <CDropdownMenu className="pt-0">
                    {cardColors.map((card, i) => (
                      <CDropdownItem
                        key={i}
                        className={card.color}
                        onClick={() =>
                          handleCardColor(card.color, card.text)
                        }
                      ></CDropdownItem>
                    ))}
                  </CDropdownMenu>
                </CDropdown>
              </CCol>
              <CCol  className="text-right">
                <CButton className="bg_random redo_btn" onClick={() => resetFilter()} >
                  <FaRedo className="mr-2 xs-font xs-size" name="cil-clear-all" />
                </CButton>
              </CCol>
              <CCol  className="text-right left_random">
                <CButton className="bg_random" onClick={() => shuffleData()}>
                  <FaRandom className="mr-2 xs-font xs-size" name="cil-clear-all" />
                </CButton>
              </CCol>
            </div>
          </div>
          {fileLoading && <CSpinner color="success" size="sm" />}
          <div style={{ display: !IsCard ? "" : "none" }}>
            <Topic fileName={activeFile} cardColors={cardColor} IsCard={IsCard} IsPicture={IsPicture} filteredData={filteredData} shuffledClick={shuffledClick} setShuffledClick={setShuffledClick}/>
          </div>
          <div style={{ display: IsCard ? "" : "none" }}>
            <TableDetails setFiles={setFiles} excelTableData={excelTableData} excelTableFields={handleTableField(excelTableFields)} activeFile={activeFile} setFileName={setFileName} />
          </div>
        </>
      ) : (
        <CRow className="ml-2">
          <CIcon name="cil-arrow-left" />
          <p className="text-black-50 ml-2">Select a file</p>
        </CRow>
      )}
    </div>
  );
};

export { FileDetails };
