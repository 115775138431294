export const imageUploadConstants = {

    UPLOAD_REQUEST: 'IMAGES_UPLOAD_REQUEST',
    UPLOAD_SUCCESS: 'IMAGES_UPLOAD_SUCCESS',
    UPLOAD_FAILURE: 'IMAGES_UPLOAD_FAILURE',

    LOADER_REQUEST: 'LOADER_REQUEST',

    GETIMAGES_REQUEST: 'IMAGES_GET_REQUEST',
    GETIMAGES_SUCCESS: 'IMAGES_GET_SUCCESS',
    GETIMAGES_FAILURE: 'IMAGES_GET_FAILURE',
 
};