import config from '../config';
import { authHeader } from '../helpers';

const getAllImagesTags = async () => {
    const user = await JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/upload/user/${user.id}`, requestOptions).then(handleResponse);
}

const createUploadFile = async(files) => {
    const user = await JSON.parse(localStorage.getItem('user'));
    if(files && files.length > 0){
        
        var formData = new FormData();
        formData.append('userId', user.id)
        for (let i=0;i<files.length;i++) {
            formData.append('files', files[i])
        }
        const requestOptions = {
            method: 'POST',
            headers: {'Authorization': 'Bearer ' + user.accessToken},
            body: formData
        };
        return fetch(`${config.apiUrl}/upload`, requestOptions).then(handleResponse);
    }
    
}

const handleResponse = (response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

export const fileUploadServices = {
    createUploadFile,
    getAllImagesTags
 };