
import React, { useEffect, Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { history } from './helpers';
import { alertActions } from './actions';
import { PrivateRoute, Wrapper } from './components';
import "./assets/css/custom.css"
import FileDetails from './components/FileDetails';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)
const LayoutPage = React.lazy(
    () => import('./containers').then(module => ({ default: module.LayoutPage }))
);
const LoginPage = React.lazy(
    () => import('./containers').then(module => ({ default: module.LoginPage }))
);
const RegisterPage = React.lazy(
    () => import('./containers').then(module => ({ default: module.RegisterPage }))
);


const App = () => {
    const alert = useSelector(state => state.alert);
    const dispatch = useDispatch();

    useEffect(() => {
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    return (
        <Wrapper>
            {alert.message &&
                <div className={`alert ${alert.type}`}>{alert.message}</div>
            }
            <Router history={history}>
                <Suspense fallback={loading}>
                    <Switch>
                        <Route exact path="/login" name="Login" component={LoginPage} />
                        <Route exact path="/register" name="Register" component={RegisterPage} />
                        <PrivateRoute path="/" name="Home" component={LayoutPage} />
                    </Switch>
                </Suspense>
            </Router>
        </Wrapper>
    );
}

export default App;
