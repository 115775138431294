import { imageUploadConstants } from "../constants";
import { fileUploadServices } from "../services"


const createImageUpload = (files) => {
    const request = (imageUpload) => { return { type: imageUploadConstants.UPLOAD_REQUEST, imageUpload } }
    const loaderImg = (loaderImg) => { return { type: imageUploadConstants.LOADER_REQUEST, loaderImg } }
    const success = (imageUpload) => { return { type: imageUploadConstants.UPLOAD_SUCCESS, imageUpload } }
    const failure = (error) => { return { type: imageUploadConstants.UPLOAD_FAILURE, error } }

    return dispatch => {
        dispatch(request({files}));
        dispatch(loaderImg(true))
        fileUploadServices.createUploadFile(files)
            .then((imageUpload,error)=>{
                if(imageUpload){
                    dispatch(success(imageUpload))
                    dispatch(loaderImg(false))
                }else{
                    dispatch(failure(error.toString()))
                }
            });
    };
}

const getAllImagesById = () => {
    const request = () => { return { type: imageUploadConstants.GETIMAGES_REQUEST } }
    const success = (imageUpload) => { return { type: imageUploadConstants.GETIMAGES_SUCCESS, imageUpload } }
    const failure = (error) => { return { type: imageUploadConstants.GETIMAGES_FAILURE, error } }

    return dispatch => {
        dispatch(request());

        fileUploadServices.getAllImagesTags()
            .then(
                imageUpload => dispatch(success(imageUpload)),
                error => dispatch(failure(error.toString()))
            );
    };
}

export const imageUploadActions = {
    createImageUpload,
    getAllImagesById
}