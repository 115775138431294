import config from '../config';
import { authHeader } from '../helpers';

const getAllDataSets = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if(user){
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };
    
        return fetch(`${config.apiUrl}/datasets`, requestOptions).then(handleResponse);

    }else{
        return fetch(`${config.apiUrl}/public/datasets`).then(handleResponse);
    }
}

const getDataSet = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/dataset/${id}`, requestOptions).then(handleResponse);
}

const createDataSet = (rows, headers, fileName,fileType,description,createdAt,updatedAt) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken},
        body: JSON.stringify({fileName: fileName, data: {rows: rows, headers: headers}, userId: user.id, fileType: fileType,description:description,createdAt:createdAt,updatedAt:updatedAt})
    };

    return fetch(`${config.apiUrl}/dataset`, requestOptions).then(handleResponse);
}

const updateDataSet = (rows, headers, fileName, fileType, fileId,updatedAt) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken},
        body: JSON.stringify({data: {rows: rows, headers: headers}, fileName:fileName, userId: user.id, fileType: fileType,updatedAt:updatedAt})
    };

    return fetch(`${config.apiUrl}/dataset/`+fileId, requestOptions).then(handleResponse);
}

const deleteDataSet = (id) => {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/dataset/${id}`, requestOptions).then(handleResponse);
}

const handleResponse = (response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

const setFileList = (response) => {
    return response
} 

export const dataSetService = {
    getAllDataSets,
    getDataSet,
    createDataSet,
    updateDataSet,
    deleteDataSet,
    setFileList
};