import React from 'react'

function ContactUs() {
    return (
        <div>
            <h3>Contact Us</h3>
            <h4>Please contact us in this email : <a href="">john@superknowing.com</a></h4>
        </div>
    )
}

export {ContactUs}
