import React, { useState, useEffect, useRef } from "react";
import {
  CCard,
  CCardBody,
  CCardTitle,
  CContainer,
  CCol,
  CRow,
  CButton,
  CCollapse,
  CSelect,
  CImg

} from "@coreui/react";
import Carousel from "react-multi-carousel";
import "../styles/cardstyle.css";
import "react-multi-carousel/lib/styles.css";
import "../styles/style.css";
import CIcon from "@coreui/icons-react";
import { useSelector, useDispatch } from "react-redux";
import { commanActions, dataSetActions } from "../actions";
import { RiCloseLine } from "react-icons/ri";
import MultiRangeSlider from "./Rangslider";
import Plot from "react-plotly.js";
import _ from "lodash"
import { dataSetConstants } from '../constants';
import { imageConstants } from "../constants";
import { image } from "../reducers/image.reducer";
import LeftAerrow from '../assets/icons/leftAerrow.svg'
import leftArrowMobile from '../assets/icons/leftArrowMobile.svg'
import RightAerrow from '../assets/icons/rightAerrow.svg'
import rightArrowMobile from '../assets/icons/rightArrowMobile.svg'
import EyeDroper from '../assets/icons/eyeDroper.svg'
import Filter from '../assets/icons/Filter.svg'
import FaCancel from '../assets/icons/FaCancel.svg'
import FaRotate from '../assets/icons/Rotate.svg'
import Eye from '../assets/icons/Eye.svg'

const cardColors = [
  {
    color: "bg-gradient-primary",
    text: "text-white",
  },
  {
    color: "bg-gradient-info",
    text: "text-white",
  },
  {
    color: "bg-gradient-warning",
    text: "text-primary",
  },
  {
    color: "bg-gradient-success",
    text: "text-white",
  },
  {
    color: "bg-gradient-danger",
    text: "text-white",
  },
  {
    color: "bg-gradient-dark",
    text: "text-white",
  },
  {
    text: "text-black",
  },
  {
    color: "bg-secondary",
    text: "text-black",
  },
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
var hide_nemu = false;
let index_item = 0;
const Cards = (props) => {

  const windowWidth = window.innerWidth

  const dispatch = useDispatch();
  const [collapse, setCollapse] = useState(false);
  const [CardsData, setCardsData] = useState();
  const [filteredData, setFilteredData] = useState(null);
  const [state, setState] = useState();
  const Showheader = useSelector((state) => state.datasets.showAnswer);
  const carouselRef = useRef();
  const [cardColor, setCardColor] = useState({
    text: "text-black",
  });
  let { cardData, fileName, cardColors, showPicture, resetFilteredData, shuffledClick, setShuffledClick, IsCard } = props;
  const isAnswer = useSelector((state) => state.datasets.isAnswer);
  const [hideShowArr, sethideShowArr] = useState([]);
  const [hideShowNewArr, sethideShowNewArr] = useState([]);
  const [closeArr, setCloseArr] = useState([]);
  const [keyBackup, setKeyBackup] = useState([]);
  const [deleteArr, setDeleteArr] = useState([]);
  const [OpenType, setOpenType] = useState(false);
  const [hoverCardIcon, setHoverCardIcon] = useState(true);
  const [indexFilter, setIndexFilter] = useState(null);
  const [filterValue, setFilterValue] = useState();
  const [filterKey, setFilterKey] = useState();
  const [inputValue, setInputValue] = useState();
  const [selectData, setSelectData] = useState();
  const [max, setMax] = useState(0)
  const [min, setMin] = useState(0)
  const [total, setTotal] = useState(0)
  const activeCardList = useSelector((state) => state.datasets.activeCard)
  const [isReload, setReload] = useState("");
  const [pageNum, setPageNum] = useState(0);
  const setFiles = useSelector((state) => state.datasets.file);
  const [shuffleColunmData, setShuffleColunmData] = useState('');
  const handleActiveItem = (file) => {
    if (setFiles && !File) {
      // setActiveFileType(file.fileType)
      // setFileLoading(true);
      // setActiveFile(setFiles.fileName);
      const rows = setFiles.data.rows;
      const headers = setFiles.data.headers;

      let rowData = [];
      for (let i = 0; i < rows.length; i++) {
        let row = {};

        for (let j = 0; j < headers.length; j++) row[headers[j]] = rows[i][j];
        rowData.push(row);
      }

      // setExcelTableData(rowData);
      // setExcelTableFields(headers);
      // setFileLoading(false);
      // setFile(true);
    } else if (file) {
      // setActiveFileType(file.fileType)
      // setFileLoading(true);
      // setActiveFile(file.fileName);
      const rows = file.data.rows;
      const headers = file.data.headers;

      let rowData = [];
      for (let i = 0; i < rows.length; i++) {
        let row = {};

        for (let j = 0; j < headers.length; j++) row[headers[j]] = rows[i][j];
        rowData.push(row);
      }

      // setExcelTableData(rowData);
      // setExcelTableFields(headers);
      // setFileLoading(false);
    }
  };

  const [startTouch, setStartTouch] = useState({ x: null, y: null });

  useEffect(() => {
    if (fileName !== isReload) {
      setReload(fileName);
    }
  }, [fileName])

  useEffect(() => {
    if (resetFilteredData) {
      if (cardData.cardRows) {
        filteredData.cardHeader = Object.keys(cardData.cardRows[0])
      }
      resetFilter()
      // setPageNum(0)
    }
  }, [resetFilteredData])

  useEffect(() => {
    const newState = {};
    newState["maxColsMain"] = Math.min(8, cardData.cardHeader.length);
    newState["maxColsMore"] =
      cardData.cardHeader.length > 8
        ? cardData.cardHeader.length - newState["maxColsMain"]
        : null;
    newState["maxRows"] = cardData.cardRows.length;
    setState(newState);
    setFilteredData(cardData);
    setCardsData(cardData)
    handleGoSlide(0);
  }, [isReload, cardData])

  const handleGoSlide = (slide) => {
    // carouselRef &&
    //   carouselRef.current &&
    //   carouselRef.current.goToSlide(slide, true);
  };

  const toggleCollapse = (e) => {
    setCollapse(!collapse);
    e.preventDefault();
  };

  const ShowIcon = (showheader, row) => {
    const arr = hideShowArr;
    const arrRow = hideShowNewArr;
    if (arr.length > 0) {
      var index = arr.indexOf(showheader);
      if (index !== -1) {
        arr.splice(index, 1);
      }
      sethideShowArr([...new Set(arr)]);
    }
    if (arrRow.length > 0) {
      var index = arrRow.indexOf(Object.keys(row)[showheader]);
      if (index !== -1) {
        arrRow.splice(index, 1);
      }
      sethideShowNewArr([...new Set(arrRow)])
    }
    hide_nemu = false;
    index_item = showheader;
  };
  const HideIcon = (showheader, row) => {
    const arr = [];
    const arrRow = [];
    arr.push(...hideShowArr, showheader);
    // let newAry = [
    //   Object.keys(row)[showheader]:Object.values(row)[showheader]
    // ]
    arrRow.push(...hideShowNewArr, Object.keys(row)[showheader]);
    sethideShowArr([...new Set(arr)]);
    sethideShowNewArr([...new Set(arrRow)]);
    hide_nemu = true;
    index_item = showheader;
  };
  const showdiv = (index) => {
    if (hideShowArr.includes(index) === true) {
      return "transparent";
    } else if (isAnswer === true) {
      return "transparent";
    } else {
      return "black";
    }
  };
  const show_eyeicon = (index) => {
    if (hideShowArr.includes(index) === true) {
      return true;
    } else {
      return false;
    }
  };

  const showClass = (index) => {
    if (hideShowArr.includes(index) === true) {
      return true;
    } else {
      return false;
    }
  };

  const showButton = (row) => {
    const len = Object.keys(row).length;
    if (len < 9) {
      return "hide-button";
    } else {
      return "show-button";
    }
  };

  const repeatPattern = (i) => {
    let maxRow = parseInt(state.maxColsMain / 3);
    let row = parseInt((i + 1) / 3);
    let index = parseInt((state.maxColsMain - 2) % 3);
    if (i < 2) {
      return "col-sm-6";
    } else if (row < maxRow) {
      return "col-sm-4";
    } else if (row === maxRow) {
      if (index === 1) {
        return "col-sm-12";
      } else if (index === 2) {
        return "col-sm-6";
      } else {
        return "col-sm-4";
      }
    }
  };

  // pattern for Mobile

  const repeatMobilePattern = (i) => {
    let maxRow = parseInt((state.maxColsMain - 1) / 2);
    let row = parseInt(i / 2);
    let index = parseInt((state.maxColsMain - 2) % 2);
    if (i < 2) {
      return "col-12";
    } else if (row < maxRow) {
      return "col-6";
    } else if (row === maxRow) {
      if (index === 1) {
        return "col-12";
      } else {
        return "col-6";
      }
    }
  };

  const deleteHandle = (index, item) => {
    const filteredHeader = filteredData.cardHeader.filter(
      (header, headerIndex) => headerIndex !== index
    );

    let length = filteredHeader.length;

    var key = filteredData.cardHeader[index];
    const arr = [];

    const data = {};
    data.key = key;
    data.index = index;
    data.item = item;
    arr.push(...closeArr, data);
    setCloseArr([...new Set(arr)]);
    const keys = [];

    const newFilteredData = filteredData.cardRows.filter((row, rowIndex) => {
      if (row[`${key}`]) {
        keys.push(row[`${key}`]);
        delete row[`${key}`];
        return true;
      } else {
        return false;
      }
    });
    let finalData = { [key]: keys }
    setFilteredData({
      cardHeader: filteredHeader,
      cardRows: newFilteredData,
      filterFields: cardData.filterFields,
    });
    setKeyBackup({ ...keyBackup, [key]: keys });

    const newState = {};
    newState["maxColsMain"] = Math.min(8, length);
    newState["maxColsMore"] =
      length > 8 ? length - newState["maxColsMain"] : null;
    newState["maxRows"] = length;
    setState(newState);

    window.screen.width > 575
      ? repeatPattern(index)
      : repeatMobilePattern(index);
  };

  const redoHandle = (item, index) => {
    let arr = closeArr;
    const key = item.key;
    const filter = filteredData;
    arr.splice(index, 1);
    setCloseArr([...new Set(arr)]);

    var keys = filteredData.cardHeader[index];
    const data = {};
    data.key = keys;

    filteredData.cardRows.map((item, innerIndex) => item[key] = keyBackup[key][innerIndex])
    filteredData.cardHeader.push(key)

    setFilteredData(filteredData);
    // for (let i = 0; i < filteredData.cardRows.length; i++) {
    //   filter.cardRows[i][key] = keyBackup[i];
    // }
    // setFilteredData({
    //   cardHeader: filter.cardHeader,
    //   cardRows: filter.cardRows,
    //   filterFields: cardData.filterFields,
    // });

    const length = filter.cardHeader.length;
    const newState = {};
    newState["maxColsMain"] = Math.min(8, length);
    newState["maxColsMore"] =
      length > 8 ? length - newState["maxColsMain"] : null;
    newState["maxRows"] = length;
    setState(newState);
  };

  const filterHandle = (index, key, value) => {
    setIndexFilter(filteredData.filterFields[filteredData.cardHeader[index]][0])
    setFilterValue(key)

    var key = filteredData.cardHeader[index];
    const arr = [];
    let data = {}
    data.key = key;
    const keys = [];

    const newFilteredData = activeCardList.cardRows.filter((row, rowIndex) => {
      if (row[`${key}`]) {
        keys.push(row[`${key}`]);
        return true;
      } else {
        return false;
      }
    });
    setFilterKey(keys)
    setOpenType(true)
    setSelectData(<CSelect
      custom
      name="filterSelect"
      onChange={(e) => setInputValue(e.target.value)}
    >
      {filteredData.filterFields[
        filteredData.cardHeader[index]
      ].map((dataitem, dataindex) => (
        <option
          key={dataindex}
          value={dataitem}
          atr-data={dataitem.length}
        >
          {dataitem}
        </option>
      ))}
    </CSelect>)
  };

  const handleFilterCardData = (e, indexFilter) => {
    let arr = []
    setPageNum(0);
    setFilteredData(CardsData)
    if (indexFilter !== null && (Number(indexFilter))) {
      arr = activeCardList.cardRows.filter(item => Number(item[filterValue]) >= min && Number(item[filterValue]) <= max)
      setFilteredData({ ...filteredData, cardRows: arr })
    } else if (indexFilter && indexFilter !== null && indexFilter.match(/\$/)) {
      arr = activeCardList.cardRows.filter(item => Number(item[filterValue].slice(1).trim().replace(',', '')) >= min && Number(item[filterValue].slice(1).trim().replace(',', '')) <= max)
      setFilteredData({ ...filteredData, cardRows: arr })
    } else {
      arr = activeCardList.cardRows.filter(item => item[filterValue] === inputValue)
      setFilteredData({ ...filteredData, cardRows: arr })
    }
    setOpenType(false)
    setCardsData(CardsData)
    filteredData.cardHeader = Object.keys(cardData.cardRows[0])
  }

  const handleMobileSize = (i, row) => {
    if (window.screen.width < 575) {
      let maxRow = parseInt((state.maxColsMain - 1) / 2);
      if (i < 2 && maxRow > 0) {
        return "largeCard";
      } else if (i < 2) {
        return "extraLargeCard"
      } else {
        if (Object.values(row)[i].includes("{")) return "smallCard phtoUrl"
        return "smallCard"
      }
    }
  }

  const webViewCardHeight = (i, row) => {
    let maxRow = parseInt((state.maxColsMain - 1) / 2);

    // console.log("Object.keys(row).length",Object.keys(row).length, i)
    if (Object.keys(row).length < 3) {
      if (Object.values(row) && Object.values(row)[i] && Object.values(row)[i].includes("{")) return "extraLargeCardWeb phtoUrl"
      if (i < 2) {
        return "extraLargeCardWeb"
      }
    } else if (Object.keys(row).length < 6) {
      if (Object.values(row) && Object.values(row)[i] && Object.values(row)[i].includes("{")) return "largeCardWeb phtoUrl"
      if (i < 2) {
        return "largeCardWeb"
      } else if (i >= 2 && i < 5) {
        return "mediumCardWeb"
      }
    } else {
      if (Object.values(row) && Object.values(row)[i] && Object.values(row)[i].includes("{")) return "largeCardWeb phtoUrl"
      if (i < 2) {
        return "largeCardWeb"
      } else if (i >= 2 && i < 5) {
        return "mediumCardWeb"
      }
    }
  }

  const resetFilter = () => {
    setFilteredData({ ...filteredData, cardRows: activeCardList.cardRows })
    if (cardData.cardRows) {
      filteredData.cardHeader = Object.keys(cardData.cardRows[0])
    }
  }

  const filterModal = (indexFilter) => {

    if (indexFilter !== null && (Number(indexFilter))) {
      return (
        <>
          <div className="numberFilterDiv">
            <Plot
              data={[
                {
                  type: "bar",
                  x: filterKey,
                  orientation: "v",
                  hoverinfo: "none",
                  showlegend: false,
                  marker: { color: "#eee" }
                }
              ]}
              layout={{
                margin: {
                  l: 0,
                  r: 0,
                  t: 0,
                  b: 0,
                  pad: 0
                },
                width: 390,
                autosize: true,
                height: 100,
                title: "",
                xaxis: {
                  type: "linear",
                  fixedrange: true,
                  showline: false,
                  showgrid: false,
                  showticklabels: false,
                  zeroline: false
                },
                yaxis: {
                  type: "linear",
                  fixedrange: true,
                  showline: false,
                  showgrid: false,
                  showticklabels: false,
                  zeroline: false
                },
                clickmode: "none",
                hovermode: false,
                showlegend: false,
                bargap: 0
              }}
              options={{
                displayModeBar: false
              }}
            />
            <MultiRangeSlider
              min={Math.min(...filterKey)}
              max={Math.max(...filterKey)}
              onChange={({ min, max }) => { setMax(max); setMin(min) }}
            />
          </div>
        </>
      )
    } else if (indexFilter && indexFilter !== null && indexFilter.match(/\$/)) {
      let key = filterKey.map(i => i.slice(1).trim().replace(',', ''));
      return (
        <>
          <div className="numberFilterDiv">
            <Plot
              data={[
                {
                  type: "bar",
                  x: key,
                  orientation: "v",
                  hoverinfo: "none",
                  showlegend: false,
                  marker: { color: "#00000085" }
                }
              ]}
              layout={{
                margin: {
                  l: 0,
                  r: 0,
                  t: 0,
                  b: 0,
                  pad: 0
                },
                width: 390,
                autosize: true,
                height: 100,
                title: "",
                xaxis: {
                  type: "linear",
                  fixedrange: true,
                  showline: false,
                  showgrid: false,
                  showticklabels: false,
                  zeroline: false
                },
                yaxis: {
                  type: "linear",
                  fixedrange: true,
                  showline: false,
                  showgrid: false,
                  showticklabels: false,
                  zeroline: false
                },
                clickmode: "none",
                hovermode: false,
                showlegend: false,
                bargap: 0
              }}
              options={{
                displayModeBar: false
              }}
            />
            <MultiRangeSlider
              min={Math.min(...filterKey.map(i => i.slice(1).trim().replace(',', '')))}
              max={Math.max(...filterKey.map(i => i.slice(1).trim().replace(',', '')))}
              onChange={({ min, max }) => { setMax(max); setMin(min) }}
            />
          </div>
        </>
      )
    } else {
      return (
        <>
          <div>
            {selectData}
          </div>
        </>
      )
    }
  }
  const [shuffleRowData, setShuffleRowData] = useState('')

  useEffect(() => {

    if (filteredData && filteredData.cardRows.length) {
      const newState = _.cloneDeep(state);
      newState["maxRows"] = filteredData.cardRows.length;
      setState(newState);
    }
    setTotal(filteredData && filteredData.cardRows.length)

    if (filteredData && filteredData.cardRows.length > 0) {
      const rowIndex = pageNum;
      setShuffleRowData(filteredData.cardRows[rowIndex])
    }

  }, [filteredData])

  useEffect(() => {
    if (shuffledClick == 'click') {

      let shuffledData = filteredData.cardRows
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)
      setShuffleColunmData(shuffledData)
      const rowIndex = pageNum;
      if (filteredData && filteredData.cardRows.length > 0) {
        setShuffleRowData(filteredData.cardRows[rowIndex])
      }
      let unshuffled = Object.entries(filteredData.cardRows[rowIndex])
      let shuffled = unshuffled
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)

      // setShuffleRowData(Object.fromEntries(shuffled))
      // filteredData.cardHeader = Object.keys(Object.fromEntries(shuffled))
    }
    setShuffledClick('')
  }, [shuffledClick])

  useEffect(() => {
    if (filteredData && filteredData.cardRows.length > 0) {
      setShuffleRowData(filteredData.cardRows[pageNum])
    }
  }, [pageNum])

  useEffect(() => {

    const handleUserKeyPress = event => {
      if(!IsCard){
        switch (event.keyCode) {
          case 37:
            document.getElementById('previousButton').click()
            break;
          case 39:
            document.getElementById('nextButton').click()
            break;
        }
      }
    };

    window.addEventListener("keydown", handleUserKeyPress);

    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };

  }, [IsCard])

  // useEffect(() => {
  //   let touchstartX; let touchstartY; let touchendX; let touchendY;

  //   const handleGesture = () => {
  //     if (touchendX < touchstartX) {
  //       document.getElementById('nextButton').click()
  //     }

  //     if (touchendX > touchstartX) {
  //       document.getElementById('previousButton').click()
  //     }
  //   }

  //   const handleUserTouchStart = (event) => {
  //     if(!IsCard){
  //       touchstartX = event.changedTouches[0].screenX;
  //       touchstartY = event.changedTouches[0].screenY;
  //     }
  //   }
  //   const handleUserTouchEnd = (event) => {
  //     if(!IsCard){
  //       touchendX = event.changedTouches[0].screenX;
  //       touchendY = event.changedTouches[0].screenY;
  //       handleGesture();
  //     }
  //   }

  //   window.addEventListener("touchstart", handleUserTouchStart);
  //   window.addEventListener("touchend", handleUserTouchEnd);

  //   return () => {
  //     window.removeEventListener("touchstart", handleUserTouchStart);
  //     window.removeEventListener("touchend", handleUserTouchEnd);
  //   };

  // }, [IsCard])

  const onPageNumClick = (pageIndex,event) => {
    console.log("event", event)
    pageIndex = (pageIndex + state.maxRows) % state.maxRows;

    console.log("pageindex", pageIndex)
    if (pageIndex === pageNum) return;
    setPageNum(pageIndex);
    if (cardData.cardRows) {
      filteredData.cardHeader = Object.keys(cardData.cardRows[0])
    }
  }

  const [hoverState, setHoverState] = useState([]);

  const MouseLeave = (showheader, row) => {
    const arrRow = hoverState;
    if (arrRow.length > 0) {
      var index = arrRow.indexOf(Object.keys(row)[showheader]);
      if (index !== -1) {
        arrRow.splice(index, 1);
      }
      setHoverState([...new Set(arrRow)])
    }

  }
  const MouseEnter = (showheader, row) => {
    const arrRow = [];
    arrRow.push(...hoverState, Object.keys(row)[showheader]);
    setHoverState([...new Set(arrRow)]);
  }

  const renderContainer = () => {
    const rowIndex = pageNum;
    let row = shuffleColunmData ? shuffleColunmData[rowIndex] : filteredData.cardRows[rowIndex]
    let result = []
    if (row) {
      let items = Object.entries(row)

      let sorting = filteredData.cardHeader

      sorting.forEach(function (key) {
        var found = false;
        items = items.filter(function (item) {
          if (!found && item[0] == key) {
            result.push(item);
            found = true;
            return false;
          } else
            return true;
        })
      })
      row = Object.fromEntries(result)
    }

    var i_index = Object.values(row).findIndex(v => v && typeof v != 'object' && typeof v == 'string' && typeof v != "undefined" && v.includes("{"));

    var indices = Object.values(row).reduce(function (r, v, i) {
      return r.concat(typeof v == 'string' && typeof v != "undefined" && v.includes('photoUrl') ? i : []);
    }, []);

    if (i_index != -1) {
      dispatch({ type: imageConstants.IMAGE, image: true })

    } else {
      dispatch({ type: imageConstants.IMAGE, image: false })

    }

    // mobile swipe left right event
    const handleTouchStart = (e) => {
      setStartTouch({ x: e.touches[0].clientX, y: e.touches[0].clientY });
    };

    const handleTouchEnd = (e) => {

      const dx = e.changedTouches[0].clientX - startTouch.x;
      const dy = e.changedTouches[0].clientY - startTouch.y;
      const absDx = Math.abs(dx);
      const absDy = Math.abs(dy);

      if (Math.max(absDx, absDy) > 100) {
        if (absDx > absDy) {
          if (dx > 0) {
            // Right swipe
            document.getElementById('previousButton').click()
          } else {
            // Left swipe
            document.getElementById('nextButton').click()
          }
        }
      }
    };
    return (
      <CCol sm="10" xs="12"  onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
        <CRow className="justify-content-center align-center ">
        </CRow>
        <CRow className={'mainTable'}>
          <CCard className="card-carousel-data card-align-center contant-widget card_remove card_pagination" >
            <div className="card-button">
              <CButton
                id="previousButton"
                className={"bg_info showclass"}
                // style={{position:"absolute",left:"0",zIndex:"1000"}}
                onClick={() => (rowIndex + 1) != 1 ? onPageNumClick(pageNum - 1) : onPageNumClick(total - 1)}
              >
                <CImg className="mr-1 left_prev" src={leftArrowMobile} alt="leftArrowMobile" />
              </CButton>

            </div>
            <CCardBody className={'cards_card_body'}>
              <CRow>
                {Object.values(row)
                  .slice(0, state.maxColsMain)
                  .map((item, rowItemIndex) => (
                    <CCol
                      key={rowItemIndex}
                      className={
                        window.screen.width > 575
                          ? `${repeatPattern(rowItemIndex)}`
                          : `${repeatMobilePattern(
                            rowItemIndex
                          )} card-adjustment`
                      }
                    >
                      <CCard
                        onMouseEnter={() => MouseEnter(rowItemIndex, row)}
                        onMouseLeave={() => MouseLeave(rowItemIndex, row)}
                        className={`text-center card-rounded ${hoverState.includes(Object.keys(row)[rowItemIndex]) ? 'showtooltip' : 'hidetooltip'} ${cardColors.color} ${window.screen.width < 575 ? handleMobileSize(rowItemIndex, row) : webViewCardHeight(rowItemIndex, row)} p-3`}
                      >
                        <CCardTitle
                          className={`text-left ${cardColors.text} font-weight-bold h5 header-size`}
                        >
                          <div className="action_header_fixsize">
                            <span className="cardname card_font">
                              {filteredData.cardHeader[rowItemIndex]}
                            </span>
                            <div className="action_header header-size">
                              <div
                                className={`sameIconHover eyeIconcard ${isAnswer ? "disabled" : "enable"}`}
                              >
                                {hideShowNewArr.includes(Object.keys(row)[rowItemIndex]) ? (
                                  <div
                                    onClick={() =>
                                      ShowIcon(rowItemIndex, row)
                                    }
                                  >
                                    <CImg src={EyeDroper} className={'eye_img'} />
                                  </div>
                                ) : (
                                  <div
                                    onClick={() =>
                                      HideIcon(rowItemIndex, row)
                                    }
                                  >
                                    <CImg src={Eye} className={'eye_img'} />
                                  </div>
                                )}
                              </div>
                              <div
                                className="sameIconHover filter-btn"
                                onClick={() =>
                                  filterHandle(rowItemIndex, filteredData.cardHeader[rowItemIndex])
                                }
                              >
                                <CImg src={Filter} className={'eye_img'} />
                              </div>
                              <div
                                className="sameIconHover close"
                                aria-label="Close"
                                onClick={() =>
                                  deleteHandle(rowItemIndex, item)
                                }
                              >
                                <CImg src={FaCancel} className={'cansel_img'} />
                              </div>
                            </div>
                          </div>
                        </CCardTitle>
                        <CCardBody style={{
                          height: showPicture ? Object.values(row)[0][0] === "{" && rowItemIndex === 1 && '250px' : ''
                        }}
                          className={showPicture ? item && item[0] === "{" && "photoUrlSet" : ''}>
                          <div className={'font_bg'}>
                            {
                              showPicture ? item && item[0] === "{" &&

                                <img src={showPicture ? item && item[0] === "{" && `${JSON.parse(item).photoUrl}` : ''}
                                  style={{
                                    height: item && item.includes("{") ? '250px' : ''
                                  }} className={'sanators_img'} />
                                : ''
                            }
                            <h3
                              className={`customPadding border-0 content-size  h4_font
                                ${item && item.includes("{") ? 'mobile_show' : ''}
                                ${hideShowNewArr.includes(Object.keys(row)[rowItemIndex]) ? "showbackground" : "hidebackground"
                                } ${showdiv(rowItemIndex)} ${cardColors.color
                                } ${cardColors.text} `}
                            >
                              {indices.includes(rowItemIndex) ? item && item[0] === "{" ? JSON.parse(item).name : item : item}
                            </h3>
                          </div>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  ))}
              </CRow>
              {closeArr && closeArr.length ? (
                <CRow className="row_left_1">
                  {closeArr.map((item, index) => (
                    <CButton
                      key={index}
                      color="default"
                      onClick={() => redoHandle(item, index)}
                      className={"m-1 btn_close_border"}
                    >
                      {item.key}
                      <CImg className="rotateAerrow" src={FaRotate} />
                    </CButton>
                  ))}
                </CRow>
              ) : (
                ""
              )}
            </CCardBody>

            <CButton
              id="nextButton"
              className={"bg_next showclass"}
                  onClick={() =>(rowIndex + 1) != total ? onPageNumClick(pageNum + 1) : onPageNumClick(0)}
            >
                  <CImg className="ml-1 left_prev" src={rightArrowMobile } alt="rightArrowMobile" />
            </CButton>
          </CCard>
        </CRow>
        <CRow>
          <CCard
            className={`card-carousel-data-more ${showButton(row)}`}
          >
            <CCardBody>
              <CButton
                color="primary"
                onClick={toggleCollapse}
                className={"m-1 more_less_button"}
              >
                {collapse ? "Less ..." : "More ..."}
              </CButton>
              <CCollapse show={collapse} className="m-1">
                <CRow>
                  {state.maxColsMore &&
                    Object.values(row)
                      .slice(state.maxColsMain)
                      .map((item, i) => (
                        <CCol key={i} sm="4">
                          <CCard
                            className={`p-2 ${cardColors.color} ${cardColors.text} text-center`}
                          >
                            <h5>
                              {
                                filteredData.cardHeader[
                                i + state.maxColsMain
                                ]
                              }
                            </h5>
                            <p className={`${cardColors.text}`}>
                              {item}
                            </p>
                          </CCard>
                        </CCol>
                      ))}
                </CRow>
              </CCollapse>
            </CCardBody>
          </CCard>
        </CRow>

      </CCol>
    )
  }
  return (
    filteredData && (
      <div className="position-relative">
        <div className="FileTypeModal FilterDialog" style={{ display: OpenType ? "block" : "none" }}>
          <div
            className="containers"
            style={{ display: OpenType ? "block" : "none" }}
          >
            <div><CImg src={FaCancel} className="closeRight" onClick={() => setOpenType(false)} /></div>
            <div className="confirmation-text">Select Filter</div>
            <div className="button-container">
              {filterModal(indexFilter)}
              <button style={{ marginTop: '30px' }} onClick={(e) => handleFilterCardData(e, indexFilter)} >Submit </button>
            </div>
          </div>
        </div>
        {filteredData.cardRows.length ? (
          <CRow className="justify-content-center middle-align">
            {renderContainer()}
          </CRow>
        ) : (
          <CRow>
            <CCol sm="6">
              <h5 className="text-danger">
                No items found. Reset filters to view all
              </h5>
            </CCol>
            <CCol sm="6" className="text-right">
              <CButton className="bg-info" onClick={() => resetFilter()}>
                <CIcon name="cil-clear-all" />
                Reset
              </CButton>
            </CCol>
          </CRow>
        )}
      </div>
    )
  );
};

export { Cards };
