import React, { useReducer, createContext, useEffect } from "react";
import { dataSetActions } from "../actions";
import { useDispatch, useSelector } from "react-redux";

export const NavigationContext = createContext();
export const TopicCatTreeContext = createContext();

const initialStateNav = [];
const topicCatTree = [];

const reducerNav = (state, action) => {
  //console.log("state",state);
  switch (action.type) {
    case "UPDATE":
      const { category, topic, file } = action.payload;
      // alert('category '+category+' topic '+topic+' file '+file)
      const index = state.findIndex((item) => item.name === category);
      let parent = {};
      if (index === -1) {
        parent = {
          _tag: "CSidebarNavDropdown",
          name: `${category}`,
          route: "",
          show: true,
          _children: [
            {
              _tag: "CSidebarNavItem",
              name: `${topic}`,
              to: `/topic/${category}/${topic}/${file}`,
            },
          ],
        };
        return [...state, parent];
      } else {
        const child = {
          _tag: "CSidebarNavItem",
          name: `${topic}`,
          to: `/topic/${category}/${topic}/${file}`,
        };
        state[index]._children.push(child);
        return [...state];
      }
    case "DELETE":
      for (let item of state) {
        for (let i = 0; i < item._children.length; i++) {
          const pathArray = item._children[i].to.split("/");
          const filename = pathArray[pathArray.length - 1];
          if (filename === action.payload.file) {
            item._children.splice(i, 1);
            break;
          }
        }
      }
      return [...state];

    case "DELETEALL":
      state.length = 0;
      return [...state];

    default:
      return state;
  }
};

const Wrapper = (props) => {
  const dispatch = useDispatch();
  const [stateNav, dispatchNav] = useReducer(reducerNav, initialStateNav);

  let FileTags = [];
  FileTags = useSelector((state) => state.datasets.tags);

  if (stateNav.length) {
    if (!FileTags) {
      dispatch(dataSetActions.setDataSetTags(stateNav));
    } else {
      if (FileTags && FileTags.length) {
        // dispatch(dataSetActions.updateDataSetTags(stateNav,FileTags[0].id))
      }
    }
  }

  return (
    <NavigationContext.Provider value={{ stateNav, dispatchNav }}>
      <TopicCatTreeContext.Provider value={{ topicCatTree }}>
        {props.children}
      </TopicCatTreeContext.Provider>
    </NavigationContext.Provider>
  );
};

export { Wrapper };
