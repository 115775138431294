import { dataSetConstants } from '../constants';
import { dataSetService } from '../services';
import { fileTagsServices } from "../services"

const getAllDataSets = () => {
    const request = () => { return { type: dataSetConstants.GETALL_REQUEST } }
    const success = (datasets) => { return { type: dataSetConstants.GETALL_SUCCESS, datasets } }
    const datasetLoader = (datasetLoader) => { return { type: dataSetConstants.GETALL_SUCCESS_LOADER, datasetLoader } }
    const failure = (error) => { return { type: dataSetConstants.GETALL_FAILURE, error } }

    return dispatch => {
        dispatch(request());

        dataSetService.getAllDataSets()
        .then(async (datasets,error)=>{
            if(datasets){
                if(datasets.length > 0){
                    // for(let i=0;i<datasets.length;i++){
                      
                    // //  await  updateDataSet(datasets[i].data?.rows,datasets[i].data?.headers,datasets[i].fileName,datasets[i].fileType,datasets[i].id,datasets[i].description,new Date(),new Date());
                    // await  dataSetService.updateDataSet(datasets[i].data?.rows,datasets[i].data?.headers,datasets[i].fileName,datasets[i].fileType,datasets[i].id,new Date(),new Date())
                    //     .then(
                    //         file => dispatch(success(file)),
                    //         error => dispatch(failure(error.toString()))
                    //     );
                    // }
                   
                    dispatch(success( datasets.sort((a, b) => {
                        const aDate = new Date(a.createdAt)
                        const bDate = new Date(b.createdAt)
                        
                        return bDate.getTime() - aDate.getTime()
                      })))
                }
                dispatch(datasetLoader(true))
            }else{
                dispatch(failure(error.toString()))
            }
        })
    };
}

const createDataSet = (rows, headers, fileName,fileType,description,createdAt,updatedAt) => {
    const request = (dataset) => { return { type: dataSetConstants.CREATE_REQUEST, dataset } }
    const success = (dataset) => { return { type: dataSetConstants.CREATE_SUCCESS, dataset } }
    const failure = (error) => { return { type: dataSetConstants.CREATE_FAILURE, error } }

    return dispatch => {
        dispatch(request({fileName, data: {rows, headers}}));
        dataSetService.createDataSet(rows, headers, fileName,fileType,description,createdAt,updatedAt)
            .then(
                dataset => dispatch(success(dataset)),
                error => dispatch(failure(error.toString()))
            );
    };
}

const updateDataSet = (rows, headers, fileName, fileType, fileId,updatedAt) => {
    const success = (file) => { return { type: dataSetConstants.SET_FILE, file } }
    const failure = (error) => { return { type: dataSetConstants.UPDATE_FAILURE, error } }

    return dispatch => {
        dataSetService.updateDataSet(rows, headers, fileName, fileType, fileId,updatedAt)
            .then(
                file => dispatch(success(file)),
                error => dispatch(failure(error.toString()))
            );
    };
}

const deleteDataSet = (id) => {
    const request = (id) => { return { type: dataSetConstants.DELETE_REQUEST, id } }
    const success = (id) => { return { type: dataSetConstants.DELETE_SUCCESS, id } }
    const failure = (id, error) => { return { type: dataSetConstants.DELETE_FAILURE, id, error } }

    return dispatch => {
        dispatch(request(id));

        dataSetService.deleteDataSet(id)
            .then(
                dataset => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };
}

const setFileList = (file) => {
    const success = (file) => { return { type: dataSetConstants.SET_FILE, file } }

    return dispatch => {
        const data = dataSetService.setFileList(file)
        dispatch(success(data))
    };
}

const setDataSetTags = (tags,topics) => {
    return async dispatch => {
        const data = await fileTagsServices.createFileTags(tags,topics)
        dispatch({ type: dataSetConstants.SET_TAGS, tags: data });
        dispatch(getAllFileTags())
    }
}

const updateDataSetTags =  (tags,id,topics) => {
    return async dispatch => {
        const request = await fileTagsServices.updateFileTags(tags,id,topics)    
        dispatch({ type: dataSetConstants.SET_TAGS, tags: request });
        dispatch(getAllFileTags())
    }
}

const getAllFileTags = () => {

    const request = () => { return { type: dataSetConstants.GET_TAGS_REQUEST } }
    const success = (tags) => { return { type: dataSetConstants.GET_TAGS_SUCCESS, tags } }
    return dispatch => {
        dispatch(request());

        fileTagsServices.getAllFileTags()
            .then(
                tags => dispatch(success(tags))
            );
    };
}

const setIsanswer = (isAnswer) => {
    return dispatch => {
        dispatch({type: dataSetConstants.SET_IS_ANSWER, isAnswer})
    }
}

const showAnswer = (showheader) => {
    return dispatch => {
        dispatch({type: dataSetConstants.SET_SHOW_ANSWER,showheader: {
            header:showheader,
            showeye:true
        }})
    }
}

const setActiveCardFile = (payload) => {
    return dispatch => {
        dispatch({
            type: dataSetConstants.SET_ACTIVE_CARD_FILE,
            payload
        })
    }
}

export const dataSetActions = {
    getAllDataSets,
    createDataSet,
    updateDataSet,
    deleteDataSet,
    setFileList,
    setDataSetTags,
    getAllFileTags,
    updateDataSetTags,
    setIsanswer,
    showAnswer,
    setActiveCardFile
};
