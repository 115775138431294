import { sidebarConstants } from '../constants';

const initialState = {
    sidebarShow: 'responsive'
}

  
export const sidebar = (state = initialState, action) => {
    switch (action.type) {
        case sidebarConstants.SET:
            return {...state, sidebarShow: action.sidebarShow }
        default:
            return state
    }
}