import { dataSetConstants } from '../constants';

export const datasets = (state = {datasetLoader:false}, action) => {
    switch (action.type) {
        case dataSetConstants.GETALL_REQUEST:
            return {
                ...state, 
                loading: true
            };
        case dataSetConstants.GETALL_SUCCESS:
            return {
                ...state, datasets:  action.datasets
            };
        case dataSetConstants.GETALL_SUCCESS_LOADER:
            return {
                ...state, datasetLoader: action.datasetLoader
            };
        case dataSetConstants.GETALL_FAILURE:
            return {
                error: action.error
            };
        case dataSetConstants.CREATE_REQUEST:
            return {
                ...state, 
                loading: true
            };
        case dataSetConstants.CREATE_SUCCESS:
            return {
                datasets: state.datasets && state.datasets.length > 0? [action.dataset].concat(state.datasets): [action.dataset]
            };
        case dataSetConstants.CREATE_FAILURE:
            return {
                error: action.error
            };
        case dataSetConstants.UPDATE_FAILURE:
            return {
                error: action.error
            };
        case dataSetConstants.DELETE_REQUEST:
            return {
                ...state, 
                loading: true
            };
        case dataSetConstants.DELETE_SUCCESS:
            return {
                datasets: state.datasets.filter(dataset => dataset.id !== action.id)
            };
        case dataSetConstants.DELETE_FAILURE:
            return {
                error: action.error
            };
        
        case dataSetConstants.SET_FILE:
            return {...state ,file : action.file }

        case dataSetConstants.SET_TAGS:
            return {...state, tags1: action.tags}

        case dataSetConstants.GET_TAGS_SUCCESS:
            return {...state, tags: action.tags}

        case dataSetConstants.SET_IS_ANSWER:
            return {...state, isAnswer: action.isAnswer}
        
        case dataSetConstants.SET_SHOW_ANSWER:
            return {...state, showheader: action.showheader}   
            
        case dataSetConstants.SET_ACTIVE_CARD_FILE:
            return {...state, activeCard: action.payload}
            
        default:
            return state
    }
}