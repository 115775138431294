export const dataSetConstants = {
    GETALL_REQUEST: 'DATASETS_GETALL_REQUEST',
    GETALL_SUCCESS: 'DATASETS_GETALL_SUCCESS',
    GETALL_FAILURE: 'DATASETS_GETALL_FAILURE',

    GETALL_SUCCESS_LOADER: 'GETALL_SUCCESS_LOADER',

    CREATE_REQUEST: 'DATASETS_CREATE_REQUEST',
    CREATE_SUCCESS: 'DATASETS_CREATE_SUCCESS',
    CREATE_FAILURE: 'DATASETS_CREATE_FAILURE',

    UPDATE_FAILURE: 'DATASETS_UPDATE_FAILURE',

    GET_REQUEST: 'DATASETS_GET_REQUEST',
    GET_SUCCESS: 'DATASETS_GET_SUCCESS',
    GET_FAILURE: 'DATASETS_GET_FAILURE',

    DELETE_REQUEST: 'DATASETS_DELETE_REQUEST',
    DELETE_SUCCESS: 'DATASETS_DELETE_SUCCESS',
    DELETE_FAILURE: 'DATASETS_DELETE_FAILURE',

    SET_FILE: 'DATASETS_SET_FILE',
    SET_TAGS: 'DATASETS_SET_TAGS',
    GET_TAGS: 'DATASETS_GET_TAGS',


    GET_TAGS_REQUEST: 'DATASETS_GET_TAGS_REQUEST',
    GET_TAGS_SUCCESS: 'DATASETS_GET_TAGS_SUCCESS',
    GET_TAGS_FAILURE: 'DATASETS_GET_TAGS_FAILURE',

    SET_IS_ANSWER: 'SET_IS_ANSWER',
    SET_SHOW_ANSWER: 'SET_SHOW_ANSWER',

    SET_ACTIVE_CARD_FILE: 'SET_ACTIVE_CARD_FILE'
};