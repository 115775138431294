import {imageConstants} from '../constants';

const initialState = {
    image:false
}
export const image = (state = initialState, action) => {
    switch (action.type) {
        case imageConstants.IMAGE:
            return  {
                image: action.image      
            }
            // return {
            //     type: 'IMAGE',
            //     message: action.image
            // };
        default:
            return state
    }
}

