import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  CContainer,
  CCardHeader,
  CCol,
  CRow,
  CDataTable
} from "@coreui/react";
import Scrollbar from "react-scrollbars-custom";
import { useHistory } from "react-router";
import { useDropzone } from "react-dropzone";
import xlsxParser from "xlsx-parse-json";
import styled from "styled-components";
import "../styles/style.css";
import "../styles/react-confirm-alert.css";
import { imageUploadActions, sidebarActions } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { NavigationContext, TopicCatTreeContext } from "./Wrapper";
import { FaPlus } from "react-icons/fa";

const maxRowsAllowed = 200;

const getColor = (props) => {
    if (props.isDragAccept) {
      return "#00e676";
    }
    if (props.isDragReject) {
      return "#ff1744";
    }
    if (props.isDragActive) {
      return "#2196f3";
    }
    return "#eeeeee";
  };

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;
const ImageUpload = () => {
    const history = useHistory();
    const { dispatchNav } = useContext(NavigationContext);
    const dispatch = useDispatch();
    const [fileLoading, setFileLoading] = useState(false);
    const [imgTable,setImgTable] = useState([]);
    
    const user = JSON.parse(localStorage.getItem("user"));

    const TableHeader = ['ImageName','Download']

    useEffect(() => {
        dispatch(imageUploadActions.getAllImagesById());
        dispatch(sidebarActions.setSidebar(true));
      }, []);
    
    let final_arr = [];
    const ImageData = useSelector((state) => state.imageUpload.imageUpload);  
    
    if(ImageData && ImageData.length > 0){
    
        ImageData.filter((item)=>{
            let links = item.links.replace("[","").replace("]","");
            let arr = links.split(",")
            if(arr.length > 0){
                for(let i=0;i<arr.length;i++){
                    let obj = {};
                    obj['ImageName'] = arr[i];
                    obj['Download'] = 'https://userimages-superknowing.s3.amazonaws.com/'+arr[i]
                    final_arr.push(obj);
                }
            }
        })
    }

    const StyledDropzone = () => {
        const onDrop = useCallback((acceptedFiles) => {
          setFileLoading(true);
          dispatch(
            imageUploadActions.createImageUpload(acceptedFiles)
          );
        }, []);
    
        const {
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject,
        } = useDropzone({ accept: ".jpg,.jpeg,.png,.gif", onDrop });
        return (
          <div className="container dragImg">
            <Container
              {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
            >
              <input {...getInputProps()} />
              <p>Upload new Images</p>
              <FaPlus className="addFile" />
              <em className="blue-color">.jpg, .jpeg,.png and .gif</em>
            </Container>
          </div>
        );
      };
      return (
        <>
            <CContainer fluid className="p-0 bg-gray text-black">
                <CContainer>
                    <div className="manageSide">
                        <div className="catlog_header">
                            <div>
                                <h2 className="cat_header">Image Upload</h2>
                            </div>
                        </div>
                    </div>
                    <CRow>
                        <CCol lg="3" className="dragFilesCol">
                            <StyledDropzone />
                        </CCol>
                    </CRow>
                        {final_arr && final_arr.length > 0?
                        <CRow>
                            <Scrollbar
                                className="p-2"
                                style={{ width: "100%", height: 600 }}
                            >
                                <div className="image-list">
                                    <div className="image-heading">
                                        <h2>ImageName</h2>
                                        {/* <h2>Download</h2> */}
                                    </div>
                                    {final_arr && final_arr.length > 0 && final_arr.map((dataitem)=>(
                                        <div className="image-data">
                                            <div className="image-dt-val">
                                                <a href={dataitem.Download} target="_blank">{dataitem.ImageName}</a>
                                            </div>
                                            {/* <div className="image-dt-val">
                                                {dataitem.Download}
                                            </div> */}
                                        </div>
                                    ))}
                                </div>
                            </Scrollbar>
                            </CRow>:''}
                            
                </CContainer>
            </CContainer>
        </>
      )
}
export { ImageUpload }