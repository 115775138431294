import { combineReducers } from 'redux';

import { authentication } from './authentication.reducers';
import { registration } from './registration.reducers';
import { users } from './user.reducers';
import { alert } from './alert.reducers';
import { wrapper } from './wrapper.reducers';
import { sidebar } from './sidebar.reducers';
import { datasets } from './dataset.reducers';
import { image } from './image.reducer';
import { imageUpload } from './imageUpload.reducers'

const rootReducer = combineReducers({
    authentication,
    registration,
    users,
    alert,
    wrapper,
    sidebar,
    datasets,
    image,
    imageUpload
});

export default rootReducer;