import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCardFooter,
  CCol,
  CRow,
  CDataTable,
  CSelect,
  CButton,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CSpinner,
  CAlert
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import sizeof from "object-sizeof";
import { dataSetActions } from "../actions"
import Scrollbar from "react-scrollbars-custom";
import { useHistory } from "react-router";
import { confirmAlert } from "react-confirm-alert";
import "../styles/react-confirm-alert.css";
import { useDispatch, useSelector } from "react-redux";
import { RiCloseLine } from "react-icons/ri";
const ActionButton = {
  width: "70px",
  height: "34px",
  color: "#fff",
};
const headerButton = {
  color: '#fff',
  width: '100px',
  height: '40px',
  marginLeft: '-12px'
}
const headerSaveButton = {
  color: '#fff',
  width: '100px',
  height: '40px',
  marginLeft: '10px'
}
export const TableDetails = (props) => {
  const { excelTableData, excelTableFields, activeFile, setFileName, setFiles } = props
  const user = JSON.parse(localStorage.getItem("user"));
  const oldState = useSelector((state) => state.datasets.activeCard)
  const [header, setHeader] = useState([]);
  const [row, setRow] = useState();
  const [selectList, setSelectList] = useState();
  const [openType, setOpenType] = useState(false);
  const [updateClick, setUpdateClick] = useState(false);
  const [inputData, setInputData] = useState();
  const [topicError, setTopicError] = useState();
  const [deleteColumn, setDeleteColumn] = useState('');
  const [updateIndex, setUpdateIndex] = useState('');
  const [tableData, setTableData] = useState(excelTableData);
  const [tempTableData, setTempTableData] = useState(excelTableData);
  const history = useHistory();
  const dispatch = useDispatch();
  const [newNameFile, setnewNameFile] = useState()
  const [newNameDataset, setnewNameDataset] = useState()
  const [fileName, setfileName] = useState("");
  const [isNameEnable, setisNameEnable] = useState(false);
  
  useEffect(()=>{
    console.log("tempTableData",tempTableData);
  },[tempTableData])

  useEffect(() => {
    setTableData(excelTableData)
  }, [excelTableData])

  useEffect(() => {
    const rows = setFiles?.data?.rows;
    const headers = setFiles?.data?.headers
    setHeader(headers)
    let newName = ''
    excelTableData.filter((itm, index) => {
      // find multi image place - multi index  new
      var indices = Object.values(itm).reduce(function (r, v, i) {
        return r.concat(typeof v != "undefined" && typeof v == "string" && v && v.includes('photoUrl') ? i : []);
      }, []);
      if (indices.length > 0) {
        Object.values(indices).map((index) => {
          if ((Object.values(itm)[index]).includes(`{`)) {
            newName = JSON.parse(Object.values(itm)[index]).name
            const map = itm;
            let filedName = getKeyByValue(map, Object.values(itm)[index])
            itm[filedName] = newName
          }
        })
      }
    })
  }, [setFiles, excelTableData])

  useEffect(() => {
    if(!openType){
      setInputData(null)
    }
  },[openType])

  const addRow = () => {
    let headers = setFiles.data.headers;
    const remove = ["Action"];
    headers = headers.filter((value) => !remove.includes(value));
    let res = {}
    headers.forEach((item) => {
      res = { ...res, [item]: '' }
    });
    setInputData(res)
    setOpenType(true)
  }

  const updateRow = (item, index) => {
    let headers = setFiles.data.headers;
    item.id = index
    let res = {}
    headers.forEach((itm) => {
      if (itm == "Action") {
        res = { ...res, [itm]: '' }
      } else {
        if (item[itm] != '') {
          res = { ...res, [itm]: item[itm] }
        } else {
          res = { ...res, [itm]: null }
        }

      }
    });
    res.id = index
    setInputData(res)
    setUpdateIndex(index)
    setOpenType(true)
  }

  const itemChnage = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value ? e.target.value : null })
  }

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  }

  const AddRowData = async () => {

    let rows = setFiles.data.rows;
    let headers = setFiles.data.headers;
    const remove = ["Action"];
    headers = headers.filter((value) => !remove.includes(value));
    if (inputData && inputData[headers[0]] != '' && inputData[headers[0]] != null) {
      setTopicError("");
      if (inputData) {
        rows.push(inputData)
      }
      let rowDatas = [];
      for (let i = 0; i < rows.length; i++) {
        const values = Object.keys(rows[i]).map((key) => {
          return rows[i][key];
        });
        rowDatas.push(values);
      }

      tableData.push(inputData)
      setTableData(tableData)
      if(updateClick){
        tempTableData.push(inputData)
      }

      // setTempTableData()
      setOpenType(false)

    } else {
      setTopicError(headers[0] + " filed is required")
    }
  }

  const updateRowData = async () => {
    setUpdateClick(true)
    let rows = setFiles.data.rows;
    let updated_data = null;

    if (updateIndex >= 0) {
      updated_data = oldState.cardRows[updateIndex];
      if(updated_data){
        var indices = Object.values(updated_data).reduce(function (r, v, i) {
          return r.concat(typeof v != "undefined" && v && v.includes('photoUrl') ? i : []);
        }, []);
        if (indices.length > 0) {
          Object.values(indices).map((index) => {
            let photoName = Object.keys(updated_data)[index]
            let photoJson = updated_data[photoName]
            inputData[photoName] = JSON.stringify({
              name: inputData[photoName],
              photoUrl: JSON.parse(photoJson).photoUrl
            })
          })
        }
      }
    }

    let headers = setFiles.data.headers;

    const remove = ["Action"];
    headers = headers.filter((value) => !remove.includes(value));
    if (inputData) {
      rows[inputData?.id] = inputData
    }
    const result = rows.map(({ Action, id, ...rest }) => ({ ...rest }));
    let rowDatas = [];
    for (let i = 0; i < result.length; i++) {
      const values = Object.keys(result[i]).map((key) => {
        return result[i][key];
      });
      rowDatas.push(values);
    }

    var rowHeaderData = [];
    for (let i = 0; i < rowDatas.length; i++) {
      let row = {};
      for (let j = 0; j < headers.length; j++) 
        row[headers[j]] = rowDatas[i][j] ? rowDatas[i][j] : '';
      rowHeaderData.push(row);
    }
    setTempTableData(rowHeaderData)

    let rowHeaderDataTable = [];
    for (let i = 0; i < rowDatas.length; i++) {
      let row = {};
      for (let j = 0; j < headers.length; j++) 
        row[headers[j]] = rowDatas[i][j] ? rowDatas[i][j] : '';
      rowHeaderDataTable.push(row);
    }

      let newName = ''
      rowHeaderDataTable.filter((itm, index) => {
        // find multi image place - multi index  new
        var indices = Object.values(itm).reduce(function (r, v, i) {
          return r.concat(typeof v != "undefined" && typeof v == "string" && v && v.includes('photoUrl') ? i : []);
        }, []);
        if (indices.length > 0) {
          Object.values(indices).map((index) => {
            if ((Object.values(itm)[index]).includes(`{`)) {
              newName = JSON.parse(Object.values(itm)[index]).name
              const map = itm;
              let filedName = getKeyByValue(map, Object.values(itm)[index])
              itm[filedName] = newName
            }
          })
        }
      })

    setTableData(rowHeaderDataTable)
    setOpenType(false)
  }

  const DeleteRow = (item, index) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete row?",
      buttons: [
        {
          label: "Yes, Delete it!",
          onClick: () => {

            const rows = setFiles.data.rows;
            let headers = setFiles.data.headers;
            const remove = ["Action"];
            headers = headers.filter((value) => !remove.includes(value));
            let rowData = [];
            if (rows != null) {
              for (let i = 0; i < rows.length; i++) {
                let row = {};
                for (let j = 0; j < headers.length; j++) {
                  if (i !== index) {
                    row[headers[j]] = rows[i][j];
                  }
                }
                if (i !== index) {
                  rowData.push(row);
                }
              }
            }

            let rowDatas = [];
            for (let i = 0; i < rowData.length; i++) {
              const values = Object.keys(rowData[i]).map((key) => {
                return rowData[i][key];
              });
              rowDatas.push(values);
            }
            const d = new Date();
            const updatedAt = d.getTime();
            dispatch(
              dataSetActions.updateDataSet(
                rowDatas,
                headers,
                setFiles.fileName,
                setFiles.fileType,
                setFiles.id,
                updatedAt
              )
            );

          },
        },
        {
          label: "Cancel",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  const handleSelectField = (value) => {
    const data = JSON.parse(value)
    history.push(`/uploadImageList/${data.name}/${data.index}`)
  }

  const removeKeyAry = (items, key) => {
    items.forEach(item => {
      delete item[key];
    });
    return items;
  }

  const handleDeleteField = async (value) => {
    let colName = JSON.parse(value).name;
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete '" + colName + "' column?",
      buttons: [
        {
          label: "Yes, Delete it!",
          onClick: async () => {
            if (value) {
              let headers = excelTableFields.filter(item => item != colName && item != 'Action')
              let datas = removeKeyAry(oldState.cardRows, colName)

              let rowDatas = [];
              for (let i = 0; i < datas.length; i++) {
                const values = Object.keys(datas[i]).map((key) => {
                  return datas[i][key];
                });
                rowDatas.push(values);
              }
              const d = new Date();
              const updatedAt = d.getTime();
              await dispatch(
                dataSetActions.updateDataSet(
                  rowDatas,
                  headers,
                  setFiles.fileName,
                  setFiles.fileType,
                  setFiles.id,
                  updatedAt
                )
              );
              setDeleteColumn('')
            }
          },
        },
        {
          label: "Cancel",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  const saveFinalData = () => {
    let headers = setFiles.data.headers;
    tempTableData.filter((item,key)=>{
      Object.keys(item).forEach(function(key, index) {
        if (this[key] == '') this[key] = null;
      }, item);
  
    })
  
    let rowDatas = [];
      for (let i = 0; i < tempTableData.length; i++) {
        const values = Object.keys(tempTableData[i]).map((key) => {
          return tempTableData[i][key];
        });
        rowDatas.push(values);
      }
    setUpdateClick(false)
    setnewNameFile(setFiles)
    setnewNameDataset(rowDatas)
    setisNameEnable(true)
  }

  const saveFileWithFileName = () => {
    if(fileName == ""){
      return
    }
    let headers = newNameFile.data.headers;
      if(newNameFile.fileType && newNameFile.fileType == "public"){
        const type = "private";
        dispatch(
          dataSetActions.createDataSet(
            newNameDataset,
            headers,
            fileName,
            type,
            newNameFile.description
          )
        );
        setnewNameFile(newNameFile)
        history.push("/")
      }else{
        const d = new Date();
        const updatedAt = d.getTime();
        dispatch(
            dataSetActions.updateDataSet(
              newNameDataset,
              headers,
              fileName,
              newNameFile.fileType,
              newNameFile.id,
              updatedAt
            )
          );
      }
  }
  return (

    <CCard className="container_table col-12 col-sm-10">
      <div
        className="FileTypeModal FilterDialog RowTypeModal"
        style={{ display: openType ? "block" : "none" }}
      >
        <div
          className="containers"
          style={{ display: openType ? "block" : "none" }}
        >

          <CCard className="text-dark w-100">
            <CCardHeader>
              <em className="card-header-text">

                <small> {inputData && inputData.id >= 0 ? "Update Row" : "Add Row"}</small>
                <div>
                  <RiCloseLine
                    className="closeRight"
                    onClick={() => setOpenType(false)}
                  />
                </div>
              </em>
            </CCardHeader>
            <CCardBody>
              <CForm action="" method="post" className="form-horizontal">
                {
                  topicError && <CAlert color="danger" className="text-center">{topicError}</CAlert>
                }
                <CRow>
                  {setFiles?.data?.headers.map((data, i) => {
                    if (data != "Action") {
                      return (
                        <CCol sm="6">
                          <CFormGroup row>
                            <CLabel sm="3" col htmlFor={data}>{data}</CLabel>
                            <CCol sm="9">
                              <CInput
                                type="text"
                                id={data}
                                name={data}
                                value={inputData && typeof inputData != 'undefined' ? inputData[data] : ''}
                                placeholder={data}
                                onChange={(e) => itemChnage(e)}
                              />
                            </CCol>
                          </CFormGroup>
                        </CCol>
                      )
                    }
                  })}
                </CRow>
              </CForm>
            </CCardBody>
            <CCardFooter>
              <CRow>
                <CCol sm="6" className="text-center">
                  {inputData && inputData.id >= 0 ?
                    <CButton type="button" onClick={() => { updateRowData() }} size="sm" color="info">Save</CButton>
                    :
                    <CButton type="button" onClick={() => { AddRowData() }} size="sm" color="info">Add</CButton>
                  }
                </CCol>
              </CRow>
            </CCardFooter>
          </CCard>
        </div>
      </div>
      {/* <CCardHeader>{setFileName(activeFile)}</CCardHeader> */}
      {user && (
        <CRow className="header_column_section">
          <CCol md="6">
            <div className="image_section">Image:</div>
            {header.length &&
              <CSelect className="SelectMenu" onChange={(e) => { handleSelectField(e.target.value) }}>
                <option>Select field to set image</option>
                {header && header.map((item, index) =>
                  item != 'Action' &&
                  <option value={JSON.stringify({ name: item, index })} key={index}>{item}</option>
                )}
              </CSelect>
            }
          </CCol>
          <CCol md="6">
            <div className="column_section"> Choose to Delete column: </div>
            {header.length &&
              <CSelect className="SelectMenu" onChange={(e) => { handleDeleteField(e.target.value) }} value={deleteColumn}>
                <option>Select field to delete</option>
                {header && header.map((item, index) =>
                  item != 'Action' &&
                  <option value={JSON.stringify({ name: item, index })} key={index}>{item}</option>
                )}
              </CSelect>
            }
          </CCol>
        </CRow>

      )
      }

      <CCardBody className='table_card_body'>
        {user && (
          <CRow>
            <CCol className="text-left">
              <CButton
                style={headerButton}
                className="mb-3 bg-primary"
                onClick={() => addRow()}
              >
                Add Row
              </CButton>

              <CButton
                style={headerSaveButton}
                className="mb-3 bg-primary"
                onClick={() => saveFinalData()}
              >
                Save Data
              </CButton>
            </CCol>
            <CCol className="text-right">
              <p className="text-black-50 font-sm">
                Rows: {excelTableData.length}, Columns:{" "}
                {excelTableFields.length}, Size:{" "}
                {sizeof(excelTableData) / 1000} KB
              </p>
            </CCol>
          </CRow>

        )}
        <CRow>
          <Scrollbar
            className="p-2"
            style={{ width: "100%", height: 600 }}
          >
            <CDataTable
              className="p-0"
              items={tableData && tableData}
              fields={excelTableFields}
              columnFilter
              border
              size="sm"
              outlined
              scopedSlots={{
                Action: (item, index) => {
                  return (
                    <td>
                      <CButton
                        style={ActionButton}
                        size="sm"
                        className="mr-2 bg-info"
                        onClick={() => updateRow(item, index)}
                      >
                        Update
                      </CButton>
                      <CButton
                        style={ActionButton}
                        className="bg-danger"
                        size="sm"
                        onClick={() => DeleteRow(item, index)}
                      >
                        Delete
                      </CButton>
                    </td>
                  );
                }
              }}
            />
          </Scrollbar>
        </CRow>
      </CCardBody>
      <div
        className="FileTypeModal"
        style={{ display: isNameEnable ? "block" : "none" }}
      >
        <div
          className="containers"
          style={{ display: isNameEnable ? "block" : "none" }}
        >
          <div>
          <RiCloseLine
            className="closeRight"
            onClick={() => setisNameEnable(false)}
          />
        </div>
          <div>
            <div className="description">
              <label>File Name</label>
              <input value={fileName} className="inputFileName" onChange={(e) => setfileName(e.target.value)} />
            </div>
            <div className="button-container" style={{    marginTop: '20px'}}>
                <button
                  className="submit-button"
                  onClick={() => saveFileWithFileName()}
                  style={{padding: '10px'}}
                >
                  submit
                </button>
            </div>
          </div>
        </div>
      </div>

    </CCard>
  )
}