import { imageUploadConstants } from "../constants";

export const imageUpload = (state = {imageLoad:true}, action) => {
    switch (action.type) {
        case imageUploadConstants.UPLOAD_REQUEST:
            return {
                ...state, 
                loading: true
            };

        case imageUploadConstants.LOADER_REQUEST:
            return { ...state, imageLoad: action.loaderImg };

        case imageUploadConstants.UPLOAD_SUCCESS:
            return {
                ...state,
                imageUpload: state.imageUpload && state.imageUpload.length > 0? state.imageUpload.concat(action.imageUpload): [action.imageUpload]
            };
        case imageUploadConstants.UPLOAD_FAILURE:
            return {
                error: action.error
            };
        case imageUploadConstants.GETIMAGES_REQUEST:
            return {
                ...state, 
                loading: true
            };
        case imageUploadConstants.GETIMAGES_SUCCESS:
            return {
                ...state, imageUpload:  action.imageUpload
            };
        case imageUploadConstants.GETIMAGES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}