import { sidebarConstants } from '../constants';

const setSidebar = (sidebarShow) => {
    const request = () => { return { type: sidebarConstants.SET, sidebarShow } }

    return dispatch => {
        dispatch(request(sidebarShow));
    };
}

export const sidebarActions = {
    setSidebar
};
