import React from 'react';
import {
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CRow,
    CCol,
    CForm,
    CFormGroup,
    CInput,
    CLabel,
    CSpinner,
    CAlert
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import CreatableSelect from 'react-select/creatable'

const SaveMode = (props) => {
    const handleChange = (newValue, actionMeta) => {
        props.onChangeCategory(newValue, actionMeta);
    };

    const handleCreate = (inputValue) => {
        props.onCreateCategory(inputValue);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        props.onSubmit();
    }
    return (
        <CCard className="text-dark">
            <CCardHeader>
                <em><small>   You must save in order to analyze data</small></em>
            </CCardHeader>
            <CCardBody>
                <CForm action="" method="post" className="form-horizontal">
                    <CRow>
                        <CCol sm="6">
                            <CFormGroup row>
                                <CLabel sm="3" col="sm" htmlFor="category">Category</CLabel>
                                <CCol sm="9">
                                    <CreatableSelect
                                        isClearable
                                        isDisabled={props.categories.isLoading}
                                        isLoading={props.categories.isLoading}
                                        onChange={handleChange}
                                        onCreateOption={handleCreate}
                                        options={props.categories.options}
                                        value={props.categories.value}
                                    />
                                </CCol>
                                {
                                    props.categories.isLoading && <CSpinner color="success" size="sm"/>
                                }
                            </CFormGroup>
                        </CCol>
                        <CCol sm="6">
                            <CFormGroup row>
                                <CLabel sm="3" col htmlFor="topic">Topic</CLabel>
                                <CCol sm="9">
                                    <CInput
                                        type="text"
                                        id="topic"
                                        name="topic"
                                        value={props.topic || ''}
                                        placeholder="Topic name"
                                        onChange={(e) => props.onChangeTopic(e.target.value)}
                                    />
                                </CCol>
                            </CFormGroup>
                        </CCol>
                    </CRow>
                </CForm>
            </CCardBody>
            <CCardFooter>
                <CRow>
                    <CCol sm="2">
                        <CButton type="submit" onClick={handleSubmit} size="sm" color="info"><CIcon name="cil-scrubber" /> Save</CButton>
                    </CCol>
                    <CCol sm="6">
                        {
                            props.topicError && <CAlert color="danger">{props.topicError}</CAlert>
                        }
                    </CCol>
                </CRow>
            </CCardFooter>
        </CCard>
    )
}

export { SaveMode };