import React, { useEffect, useState, useCallback } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CDataTable,
  CImg,
  CButton,
} from "@coreui/react";
import sizeof from "object-sizeof";
import Scrollbar from "react-scrollbars-custom";
import { RiCloseLine } from "react-icons/ri";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { confirmAlert } from "react-confirm-alert";
import "../styles/style.css";
import "../styles/react-confirm-alert.css";
import { imageUploadActions, dataSetActions } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { FaPlus } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { imageUploadConstants , dataSetConstants} from "../constants";

export const ListImageAction = (props) => {
  const history = useHistory();
  const { imageUpload } = useSelector((state) => state.imageUpload);
  const { imageLoad } = useSelector((state) => state.imageUpload);
  const [excelTableDatas, setexcelTableDatas] = useState([]);
  const [excelTableField, setexcelTableField] = useState([]);
  const [uploadImage, setUploadImage] = useState();
  const [tableName, setTableName] = useState(null);
  const [OpenType, setOpenType] = useState(false);
  const [selectList, setSelectList] = useState();
  const [actionManage, setActionManage] = useState();
  const [IndexOfData, setIndexOfData] = useState();
  const setFiles = useSelector((state) => state.datasets.file);
  const ActionButton = {
    width: "70px",
    height: "34px",
    color: "#fff",
  };

  const getColor = (props) => {
    if (props.isDragAccept) {
      return "#00e676";
    }
    if (props.isDragReject) {
      return "#ff1744";
    }
    if (props.isDragActive) {
      return "#2196f3";
    }
    return "#eeeeee";
  };

  const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${(props) => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
  `;

  useEffect(() => {
    if (!setFiles) {
      history.push("/");
    } else {
      const paramIndex = Number(window.location.href.split("/")[5]);
      const rows = setFiles.data.rows;
      const headers = setFiles.data.headers;
      !headers.includes("photoUrl", "Action") &&
        headers.push("photoUrl", "Action");
      let rowData = [];
      if (rows != null) {
        for (let i = 0; i < rows.length; i++) {
          let row = {};
          for (let j = 0; j < headers.length; j++) {
            if (j == paramIndex) {
              row[headers[j]] = rows[i][j];
            }
          }
          rowData.push(row);
        }
        setexcelTableDatas(rowData);
        const data = headers.filter(
          (item, index) => index == paramIndex && item
        );
        data.push("photoUrl", "Action");
        setexcelTableField(data);
      }
    }
  }, []);

  useEffect(() => {
    if (excelTableDatas.length > 0) {
      // var i = Object.values(excelTableDatas[0]).findIndex(
      //   (v) => typeof v != "undefined" && v.includes("{")
      // );
      // if (i > 0) {
      //   let tableName = Object.keys(excelTableDatas[0])[i];
      //   console.log("tableName",tableName)
      //   setTableName(tableName);
      // } else {
      //   console.log("excelTableDatas",window.location.href.split("/")[4])
      // }
      setTableName((window.location.href.split("/")[4]).replace(/%20/g, " "));
    }
  }, [excelTableDatas]);

  const DeleteImage = (item, index) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete image?",
      buttons: [
        {
          label: "Yes, Delete it!",
          onClick: () => {
            const headerName = (window.location.href.split("/")[4]).replace(/%20/g, " ");
            const paramIndex = Number(window.location.href.split("/")[5]);
            setFiles.data.rows.map((itm) => {
              if (itm.includes(item[headerName.trim()])) {
                let ind = itm.indexOf(item[headerName.trim()]);
                itm[ind] = JSON.parse(itm[ind]).name;
              }
            });

            const rows = setFiles.data.rows;
            let headers = setFiles.data.headers;

            const remove = ["photoUrl", "Action"];

            headers = headers.filter((value) => !remove.includes(value));

            let rowData = [];
            if (rows != null) {
              for (let i = 0; i < rows.length; i++) {
                let row = {};
                for (let j = 0; j < headers.length; j++) {
                  if (j == paramIndex && i == IndexOfData) {
                    row[headers[j]] = excelTableDatas[IndexOfData][headerName];
                  } else {
                    row[headers[j]] = rows[i][j];
                  }
                }
                rowData.push(row);
              }
            }

            let rowDatas = [];
            for (let i = 0; i < rowData.length; i++) {
              const values = Object.keys(rowData[i]).map((key) => {
                return rowData[i][key];
              });
              rowDatas.push(values);
            }

            // console.log("rowData",rowData)
            // console.log("rowDatas",rowDatas)
            // console.log("headers",headers)
            setexcelTableDatas(rowData);
            const d = new Date();
            const updatedAt = d.getTime();
            dispatch(
              dataSetActions.updateDataSet(
                rowDatas,
                headers,
                setFiles.fileName,
                setFiles.fileType,
                setFiles.id,
                updatedAt
              )
            );
      
          },
        },
        {
          label: "Cancel",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  const updateImageModel = (item, action, index) => {
    dispatch({ type: imageUploadConstants.LOADER_REQUEST, loaderImg: true })
    setIndexOfData(index);
    setOpenType(true);
    setActionManage(action);
    setSelectList(item[(window.location.href.split("/")[4]).replace(/%20/g, " ")]);
    // setSelectList(Object.values(item));
  };

  const UpdateImageUpload = () => {
    if (selectList && imageUpload) {
      let temp = {};
      imageUpload.map((item) => {
        temp = item.links;
      });
      if (selectList) {
        if (selectList.includes("name")) {
          var fieldVal = JSON.parse(selectList).name;
        } else {
          var fieldVal = selectList;
        }
      }
      const imageData = {
        name: fieldVal,
        photoUrl:
          "https://userimages-superknowing.s3.amazonaws.com/" +
          temp.replace("[", "").replace("]", ""),
      };

      const headerName = (window.location.href.split("/")[4]).replace(/%20/g, " ");
      excelTableDatas[IndexOfData][headerName] = JSON.stringify(imageData);
      setexcelTableDatas(excelTableDatas);

      const paramIndex = Number(window.location.href.split("/")[5]);
      const rows = setFiles.data.rows;
      let headers = setFiles.data.headers;

      const remove = ["photoUrl", "Action"];

      headers = headers.filter((value) => !remove.includes(value));

      let rowData = [];
      if (rows != null) {
        for (let i = 0; i < rows.length; i++) {
          let row = {};
          for (let j = 0; j < headers.length; j++) {
            if (j == paramIndex && i == IndexOfData) {
              row[headers[j]] = excelTableDatas[IndexOfData][headerName];
            } else {
              row[headers[j]] = rows[i][j];
            }
          }
          rowData.push(row);
        }
      }

      let rowDatas = [];
      for (let i = 0; i < rowData.length; i++) {
        const values = Object.keys(rowData[i]).map((key) => {
          return rowData[i][key];
        });
        rowDatas.push(values);
      }

      setexcelTableDatas(rowData);
      const d = new Date();
      const updatedAt = d.getTime();
      dispatch(
        dataSetActions.updateDataSet(
          rowDatas,
          headers,
          setFiles.fileName,
          setFiles.fileType,
          setFiles.id,
          updatedAt
        )
      );

      setOpenType(false);
    } else {
      alert("Please provide valid detail");
    }
  };

  const dispatch = useDispatch();

  const StyledDropzone = () => {
    const onDrop = useCallback((acceptedFiles) => {
      setUploadImage(acceptedFiles);
      dispatch(imageUploadActions.createImageUpload(acceptedFiles));
    }, []);

    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
    } = useDropzone({ accept: ".jpg,.jpeg,.png,.gif", onDrop });

    return (
      <div className="container dragImg">
        <Container
          {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
        >
          <input {...getInputProps()} />
          <p>Upload new Images</p>
          <FaPlus className="addFile" />
          <em className="blue-color">.jpg, .jpeg,.png and .gif</em>
        </Container>
      </div>
    );
  };

  const doneAllChanges = () => {
    dispatch({ type: dataSetConstants.GETALL_SUCCESS_LOADER, datasetLoader:false })
    history.push("/")
  }

  return (
    <>
       <div className="updatedDone"> <CButton onClick={()=> doneAllChanges()}>Done</CButton> </div>
      <div
        className="FileTypeModal FilterDialog"
        style={{ display: OpenType ? "block" : "none" }}
      >
        <div
          className="containers"
          style={{ display: OpenType ? "block" : "none" }}
        >
          <div>
            <RiCloseLine
              className="closeRight"
              onClick={() => setOpenType(false)}
            />
          </div>
          <div className="confirmation-text">
            {actionManage && actionManage == 1
              ? "Upload Image"
              : "Update Image"}
          </div>
          <div>
            <CRow>
              <CCol lg="10" className="dragFilesCol">
                <StyledDropzone />
              </CCol>
            </CRow>
          </div>
          <div className="button-container update-image-btn">
            <button
              className={imageLoad ? 'opacityDown' : ''}
              onClick={() => UpdateImageUpload()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <CCard className="container_table col-12 col-sm-10">
        <CCardBody className='imageUpload_card_body'>
          <CRow>
            <CCol className="text-right">
              <p className="text-black-50 font-sm">
                Rows: {excelTableDatas.length}, Columns:{" "}
                {excelTableField.length}, Size: {sizeof(excelTableDatas) / 1000}{" "}
                KB
              </p>
            </CCol>
          </CRow>
          <CRow>
            <Scrollbar className="p-2" style={{ width: "100%", height: 600 }}>
              {tableName != null ? (
                <CDataTable
                  className="p-0"
                  items={excelTableDatas}
                  fields={excelTableField}
                  border
                  size="sm"
                  outlined
                  scopedSlots={{
                    [tableName]: (item) => {
                      let name = item[tableName];
                      if (item[tableName] && item[tableName].includes("{")) {
                        name = JSON.parse(item[tableName])["name"];
                      }
                      return <td>{name}</td>;
                    },
                    photoUrl: (item) => {
                      let photoUrl = item[tableName];
                      if (item[tableName] && item[tableName].includes("{")) {
                        photoUrl = JSON.parse(item[tableName])["photoUrl"];
                      }
                      return (
                        <td>
                          {photoUrl && photoUrl.includes("https") ? (
                            <CImg src={photoUrl} height="80px" width="80px" />
                          ) : (
                            ""
                          )}
                        </td>
                      );
                    },
                    Action: (item, index) => {
                      let photoUrl = item[tableName];
                      if (item[tableName] && item[tableName].includes("{")) {
                        photoUrl = JSON.parse(item[tableName])["photoUrl"];
                      }
                      return (
                        <td>
                          {photoUrl && photoUrl.includes("https") ? (
                            <CButton
                              style={ActionButton}
                              size="sm"
                              className="mr-2 bg-info"
                              onClick={() => updateImageModel(item, 2, index)}
                            >
                              Update
                            </CButton>
                          ) : (
                            <CButton
                              style={ActionButton}
                              size="sm"
                              className="bg-success mr-2"
                              onClick={() => updateImageModel(item, 1, index)}
                            >
                              Upload
                            </CButton>
                          )}
                          {photoUrl && photoUrl.includes("https") && (
                            <CButton
                              style={ActionButton}
                              className="bg-danger"
                              size="sm"
                              onClick={() => DeleteImage(item, index)}
                            >
                              Delete
                            </CButton>
                          )}
                        </td>
                      );
                    },
                  }}
                />
              ) : (
                ""
              )}
            </Scrollbar>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  );
};
