import { wrapperConstants } from '../constants';

const initialState = [];

export const wrapper = (state = initialState, action) => {
    switch (action.type) {
        case wrapperConstants.UPDATE:
            const { category, topic, file } = action.payload;
            const index = state.findIndex(item => item.name === category);
            let parent = {};
            if (index === -1) {
                parent = {
                    _tag: 'CSidebarNavDropdown',
                    name: `${category}`,
                    route: '',
                    show: true,
                    _children: [
                        {
                            _tag: 'CSidebarNavItem',
                            name: `${topic}`,
                            to: `/topic/${category}/${topic}/${file}`
                        },
                    ]
                }
                return [...state, parent];
            } else {
                const child = {
                    _tag: 'CSidebarNavItem',
                    name: `${topic}`,
                    to: `/topic/${category}/${topic}/${file}`
                }
                state[index]._children.push(child);
                return [...state];
            }
        case wrapperConstants.DELETE:
            for (let item of state) {
                for (let i = 0; i < item._children.length; i++) {
                    const pathArray = item._children[i].to.split("/");
                    const filename = pathArray[pathArray.length - 1];
                    if (filename === action.payload.file) {
                        item._children.splice(i, 1);
                        break;
                    }
                }
            }
            return [...state];

        case wrapperConstants.DELETEALL:
            state.length = 0;
            return [...state];

        default:
            return state;
    }
}