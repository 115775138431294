import { userConstants } from '../constants';
import { authService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

const login = (username, password, from) => {
    const request = (user) => { return { type: userConstants.LOGIN_REQUEST, user } }
    const success = (user) => { return { type: userConstants.LOGIN_SUCCESS, user } }
    const failure = (error) => { return { type: userConstants.LOGIN_FAILURE, error } }

    return dispatch => {
        dispatch(request({ username }));

        authService.login(username, password)
            .then(
                user => { 
                    dispatch(success(user));
                    history.push(from);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
}

const logout = () => {
    authService.logout();
    return { type: userConstants.LOGOUT };
}

const register = (user) => {
    const request = (user) => { return { type: userConstants.REGISTER_REQUEST, user } }
    const success = (user) => { return { type: userConstants.REGISTER_SUCCESS, user } }
    const failure = (error) => { return { type: userConstants.REGISTER_FAILURE, error } }

    return dispatch => {
        dispatch(request(user));

        authService.register(user)
            .then(
                user => { 
                    dispatch(success());
                    history.push('/login');
                    dispatch(alertActions.success('Registration successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
}

export const authActions = {
    login,
    logout,
    register
};